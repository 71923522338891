import { Typography, Container } from "@mui/material";
import PerLocationTable from "./PerLocationTable";

export default function PerLocationContainer() {
  return (
    <Container sx={{ backgroundColor: "#fff", pt: 2 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Reports Per Location
      </Typography>
      <PerLocationTable />
    </Container>
  );
}
