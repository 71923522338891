import { DateTime } from "luxon";
import { ReportInterface, FilterInterface } from "../interfaces/interfaces";

export const getGridListCols = (width: number) => {
  if (width > 1500) {
    return 4;
  }

  if (width > 1100) {
    return 3;
  }

  if (width > 900) {
    return 2;
  }

  return 1;
};

export const filterResults = (report: ReportInterface, filters: FilterInterface) => {
  if (filters.selectedStaff && report.uid !== filters.selectedStaff.uid) {
    return;
  }

  if (filters.selectedStudent && !report?.studentIDs?.includes(filters.selectedStudent.SID)) {
    return;
  }
  if (filters.dateRange[0] && filters.dateRange[1]) {
    const jsDateStart = new Date(filters.dateRange[0]);
    const jsDateEnd = new Date(filters.dateRange[1]);
    const dtStart = DateTime.fromJSDate(jsDateStart);
    const dtEnd = DateTime.fromJSDate(jsDateEnd);
    let dtReportDate;
    if (report.whenIncidentOccured) {
      dtReportDate = DateTime.fromMillis(report.whenIncidentOccured.toMillis());
    } else {
      dtReportDate = DateTime.fromMillis(report.createdAt?.toMillis() || 0);
    }
    if (dtReportDate > dtEnd || dtReportDate < dtStart) {
      return;
    }
  }
  if (filters.contacted !== "all" && report.caregiverContact !== filters.contacted) {
    return;
  }
  if (filters.selectedITs.length > 0) {
    let checkIfSelectedIT = false;
    filters.selectedITs.forEach((selectedIT) => {
      if (report?.selectedITs?.includes(selectedIT)) {
        checkIfSelectedIT = true;
      }
    });
    if (checkIfSelectedIT === false) {
      return;
    }
  }
  if (filters.selectedSeverity.length > 0) {
    let checkIfSelectedSeverity = false;
    if (report.students && report.students.length > 0 && report.students[0].severity) {
      report.students.forEach((student) => {
        if (student.severity && filters.selectedSeverity.includes(student.severity)) {
          checkIfSelectedSeverity = true;
        }
      });
    } else {
      if (report.severity && filters.selectedSeverity.includes(report.severity)) {
        checkIfSelectedSeverity = true;
      }
    }
    if (checkIfSelectedSeverity === false) {
      return;
    }
  }
  return report;
};

export const createDateField = (report: ReportInterface) => {
  let dt;
  if (report.whenIncidentOccured) {
    dt = DateTime.fromMillis(report.whenIncidentOccured.toMillis());
  } else {
    dt = DateTime.fromMillis(report.createdAt?.toMillis() || 0);
  }
  const weekNumber = dt.weekNumber;
  const year = dt.year;
  return {
    dateString: year.toString() + weekNumber.toString(),
    yearNumber: year,
    weekNumber: weekNumber,
  };
};

export const createHeaderName = (year: number, week: number) => {
  const dt = DateTime.fromObject({
    weekYear: year,
    weekNumber: week,
  });

  const dateFromStr = dt.startOf("week").toISODate();
  const dateFromToPrint = dateFromStr.substring(dateFromStr.length - 5).replace("-", "/");
  const dateToStr = dt.endOf("week").toISODate();
  const dateToToPrint = dateToStr.substring(dateFromStr.length - 5).replace("-", "/");
  return `${dateFromToPrint}-${dateToToPrint}`;
};

export const sortArrayOfObjects = ({ arr, field }: { arr: any[]; field: string }) => {
  arr.sort(function (a, b) {
    const keyA = new Date(a[field]);
    const keyB = new Date(b[field]);
    // Compare the 2 dates
    if (keyB < keyA) return -1;
    if (keyB > keyA) return 1;
    return 0;
  });
  //arr.reverse();
  return arr;
};

type FormatEmailProps = {
  report: ReportInterface;
  displayName: string;
  text: string;
};

export const formatCommentEmail = ({ report, displayName, text }: FormatEmailProps) => {
  if (!report.createdAt) return null;
  const reportDescription =
    report.reportDescription.length > 100
      ? `${report.reportDescription.substring(0, 100)}...`
      : report.reportDescription;
  const reportCreatedDate = new Date(report.createdAt?.toMillis());
  return `<p>${displayName} added the comment below to the Incident Report originally written ${reportCreatedDate.toLocaleDateString()} with the following description</p></br></br><div style="margin-top: 8px; font-size: 10px; background-color: #fff; border-radius: 5px; padding: 8px;">${reportDescription}</div><div style="margin-top: 8px; font-size: 12px; background-color: #eee; border-radius: 5px; padding: 8px;"><i>${text}</i></div><p>Log in to report.tgpapps.org to view all comments for this report.`;
};

export const getCursorXY = (input: HTMLInputElement, selectionPoint: number | null) => {
  if (selectionPoint === null) return { x: 0, y: 0 };
  const { offsetLeft: inputX, offsetTop: inputY } = input;
  // create a dummy element that will be a clone of our input
  const div = document.createElement("div");
  const inputValue = input.value;
  // set the div content to that of the textarea up until selection
  const textContent = inputValue.substring(0, selectionPoint);
  div.innerText = textContent;
  div.style.height = "auto";
  // create a marker element to obtain caret position
  const span = document.createElement("span");
  // give the span the textContent of remaining content so that the recreated dummy element is as close as possible
  span.textContent = inputValue.substring(selectionPoint - 1, selectionPoint);
  // append the span marker to the div
  div.appendChild(span);
  // append the dummy element to the body
  document.body.appendChild(div);
  // get the marker position, this is the caret position top and left relative to the input
  const { offsetLeft: spanX, offsetTop: spanY } = span;
  document.body.removeChild(div);
  return {
    x: inputX + spanX,
    y: inputY + spanY,
  };
};

export const replaceAt = ({
  string,
  index,
  replacement,
}: {
  string: string;
  index: number;
  replacement: string;
}) => {
  return string.substring(0, index) + replacement + string.substring(index + replacement.length);
};
