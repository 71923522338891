import { useCallback } from "react";
import { createHeaderName } from "../libraries/functions";
import { ColumnInterface } from "../interfaces/interfaces";

interface SubmitFormProps {
  schoolYear: string;
}

type SubmitFormType = (props: SubmitFormProps) => ColumnInterface[];

type UseSubmitFormType = {
  generateColumns: SubmitFormType;
};

const useAnnualColumns = (): UseSubmitFormType => {
  const generateColumns = useCallback<SubmitFormType>(({ schoolYear }) => {
    const tempColumns: ColumnInterface[] = [];
    let year;
    let week;
    for (let i = 51; i >= 0; i--) {
      if (i < 26) {
        year = 2023;
        week = i + 27;
      } else {
        year = 2024;
        week = i - 25;
      }
      const columnName = year.toString() + week.toString();
      const headerName = createHeaderName(year, week);
      const field: ColumnInterface = {
        field: columnName,
        headerName: headerName,
        minWidth: 120,
        headerClassName: "tableHeader",
        cellClassName: "hoverPointer",
      };
      tempColumns.push(field);
    }
    return tempColumns;
  }, []);
  return { generateColumns };
};

export default useAnnualColumns;
