import { FormControl, Select, InputLabel, MenuItem } from "@mui/material";
import { ReportInterface } from "../../interfaces/interfaces";
import { PART_OF_DAY_ARRAY, PART_OF_DAY } from "../../libraries/objects";

type Props = {
  post: ReportInterface;
  setPost: (pV: ReportInterface) => void;
};

export default function PeriodOfIncident({ post, setPost }: Props) {
  return (
    <FormControl fullWidth sx={{ marginTop: 2 }} required>
      <InputLabel id="incidentLocationLabel">Part of Day</InputLabel>
      <Select
        labelId="incidentLocationLabel"
        id="incidentLocation"
        name="incidentLocation"
        value={post.incidentLocation ? post.incidentLocation : ""}
        onChange={(e) => setPost({ ...post, incidentLocation: e.target.value })}
        label="Part of Day"
      >
        {PART_OF_DAY_ARRAY.map((partOfDay) => (
          <MenuItem value={partOfDay} key={partOfDay}>
            {PART_OF_DAY[partOfDay]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
