import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Container,
  SelectChangeEvent,
} from "@mui/material";
import { FilterInterface } from "../../interfaces/interfaces";

type Props = {
  filters: FilterInterface;
  setFilters: (pV: FilterInterface) => void;
};

export default function CaregiversContactedFilter({ filters, setFilters }: Props) {
  const handleChange = (event: SelectChangeEvent) => {
    setFilters({ ...filters, contacted: event.target.value });
  };

  return (
    <Container sx={{ mt: 2 }}>
      <FormControl fullWidth>
        <InputLabel id="caregiver-contacted-label">Caregivers Contacted?</InputLabel>
        <Select
          labelId="aregiver-contacted-label"
          label="Caregivers Contacted?"
          value={filters.contacted}
          onChange={handleChange}
        >
          <MenuItem value="all">Any</MenuItem>
          <MenuItem value="noContact">
            I have not contacted caregivers about this incident and I have not verified someone
            will.
          </MenuItem>
          <MenuItem value="yesContact">
            Yes, I have personally contacted caregivers about this incident.
          </MenuItem>
          <MenuItem value="verifiedSomeoneElse">
            Yes, I have personally verified someone else will contact caregiver about this incident.
          </MenuItem>
          <MenuItem value="verifiedNoCommsNeeded">
            I have confirmed with a school leader or manager that I do not need to communicate about
            this incident.
          </MenuItem>
        </Select>
      </FormControl>
    </Container>
  );
}
