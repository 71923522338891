import { useContext } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import useBootstrapAPIEffect from "./hooks/useBootstrapAPIEffect";
import useBootstrapEffect from "./hooks/useBootstrapEffect";
import { Backdrop, CircularProgress } from "@mui/material";
import { routes } from "./Routes";
import { AuthContext } from "./providers/AuthProvider";
import { LicenseInfo } from "@mui/x-license-pro";
const KEY = process.env.REACT_APP_DATATABLE_KEY ?? "";
LicenseInfo.setLicenseKey(KEY);

function AppBootstrap() {
  const { loading } = useContext(AuthContext);
  useBootstrapEffect();
  useBootstrapAPIEffect();
  return (
    <>
      {loading ? (
        <Backdrop open={loading}>
          <CircularProgress />
        </Backdrop>
      ) : (
        <Router>
          <main className="root">{routes}</main>
        </Router>
      )}
    </>
  );
}

export default AppBootstrap;
