import { TextField, Box } from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ReportInterface } from "../../interfaces/interfaces";
import { DateTime } from "luxon";
import { Timestamp } from "firebase/firestore";

type Props = {
  post: ReportInterface;
  setPost: (pV: ReportInterface) => void;
};

export default function WhenIncidentOccured({ post, setPost }: Props) {
  const handleChange = (value: DateTime | null) => {
    const ISO = value?.toISO() ?? "";
    const date = new Date(ISO);
    const timestamp = Timestamp.fromDate(date);
    setPost({ ...post, whenIncidentOccured: timestamp });
  };
  return (
    <Box sx={{ mt: 2 }}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePicker
          label="Select Date"
          value={post.whenIncidentOccured?.toDate()}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </Box>
  );
}
