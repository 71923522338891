import { DateTime } from "luxon";
import { Typography } from "@mui/material";
import { ReportInterface } from "../../interfaces/interfaces";
import { Timestamp } from "firebase/firestore";

type Props = {
  report: ReportInterface;
  admin: boolean;
};

const timestampToDT = (timestamp: Timestamp) => {
  return DateTime.fromMillis(timestamp.toMillis());
};

function ReportHeader({ report, admin }: Props) {
  let dt = null;
  if (report.createdAt) {
    dt = timestampToDT(report.createdAt);
  } else if (report.timestamp) {
    dt = timestampToDT(report.timestamp);
  }
  const date = dt
    ? dt.toLocaleString({ month: "long", day: "numeric", year: "numeric" })
    : "No Date";
  const time = dt ? dt.toLocaleString({ hour: "2-digit", minute: "2-digit" }) : "";

  return (
    <>
      <Typography variant="body2">{`${report.author} - Submitted On ${date} ${time}`}</Typography>
    </>
  );
}

export default ReportHeader;
