import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Button, DialogContentText } from "@mui/material";
import { useContext } from "react";
import { Comment, ReportInterface } from "../../interfaces/interfaces";
import { AuthContext } from "../../providers/AuthProvider";
import { getDatabase, ref, set } from "firebase/database";

type Props = {
  open: boolean;
  setOpen: (newValue: boolean) => void;
  commentIndex: number;
  comments: Comment[];
  report: ReportInterface;
};

export default function DeleteCommentDialog({
  open,
  setOpen,
  commentIndex,
  comments,
  report,
}: Props) {
  const { currentUserData } = useContext(AuthContext);

  const handleClose = () => {
    setOpen(false);
  };

  const writeUserData = (data: Comment[]) => {
    if (!currentUserData) return;
    const db = getDatabase();
    set(ref(db, "comments/" + report.id), data);
  };

  const handleDelete = async () => {
    comments.splice(commentIndex, 1);
    writeUserData(comments);
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this comment? This can not be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDelete} color="error">
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
