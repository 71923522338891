import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";

export default function Instructions() {
  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5">Instructions</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography align="left" variant="body1" sx={{ padding: 1 }}>
              Incident reports should be filled out within 2 school days of when the incident
              occurred. The closer to the incident the report is filled out, the more accurate it is
              likely to be.
            </Typography>
            <Typography align="left" variant="body1" sx={{ padding: 1 }}>
              An incident report should be filled out for:{" "}
              <ul>
                <li>
                  <b>All level 3 and level 4 behaviors.</b> Level 3 and level 4 behaviors are
                  defined in The Gathering Place Code of Conduct which can be found starting on page
                  4{" "}
                  <a
                    href="https://drive.google.com/file/d/10uSJyrpvp3IFChZ5BCli8tB1G_hDNtUe/view?usp=sharing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </a>
                  . The most common level 3 and 4 behaviors are:
                  <ul>
                    <li>Hitting / Kicking</li>
                    <li>Destruction of learning environment or property</li>
                    <li>Trying to climb the black fence</li>
                    <li>Hate speech or discrimination</li>
                    <li>Threats or repeated targeting of others (bullying)</li>
                  </ul>
                </li>
                <li>
                  <b>
                    Level 2 behaviors that you believe the school leadership team needs to know
                    about or be involved in addressing.
                  </b>{" "}
                  The most common level 2 behaviors are:
                  <ul>
                    <li>Eloping</li>
                    <li>
                      Unkind developmentally inappropriate language like targeted cussing or name
                      calling.
                    </li>
                    <li>Breaking or destroying another student's work.</li>
                    <li>Verbal or physical antagonization after being asked to stop.</li>
                  </ul>
                </li>
                <li>
                  <b>Any injury that necessitated nurse attention.</b>
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
}
