import { FormGroup, Typography, Grid, FormControlLabel, Checkbox } from "@mui/material";
import { IncidentTypeInterface, ReportInterface } from "../../interfaces/interfaces";
import { incidentTypesAtom } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";

type Props = {
  post: ReportInterface;
};

type HandleChangeProps = {
  event: React.ChangeEvent<HTMLInputElement>;
  incidentType: IncidentTypeInterface;
};

export default function SelectIncidenType({ post }: Props) {
  const incidentTypes = useRecoilValue(incidentTypesAtom);

  const handleChange = ({ event, incidentType }: HandleChangeProps) => {
    if (event.target.checked) {
      post.selectedITs.push(incidentType.short);
    } else {
      const index = post.selectedITs.indexOf(incidentType.short);
      if (index > -1) {
        post.selectedITs.splice(index, 1);
      }
    }
  };
  return (
    <FormGroup>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Type of incident you are reporting (check all that apply)
      </Typography>
      {incidentTypes && (
        <Grid container spacing={2}>
          {incidentTypes.map((i) => (
            <Grid item xs={12} sm={6} key={i.id}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={i.short}
                    key={i.id}
                    onChange={(event) => handleChange({ event, incidentType: i })}
                  />
                }
                label={<Typography style={{ fontSize: "13px" }}>{i.type}</Typography>}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </FormGroup>
  );
}
