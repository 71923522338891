import React, { useState, useEffect } from "react";
import { Typography, Chip, Autocomplete, TextField, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ReportInterface } from "../../interfaces/interfaces";
import { incidentTypesAtom, allReportsResetAtom } from "../../recoil/atoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import useUpdateDoc from "../../hooks/useUpdateDoc";

type Props = {
  report: ReportInterface;
};

function IncidentTypes({ report }: Props) {
  const [selectedIncidentTypes, setSelectedIncidentTypes] = useState<string[]>([]);
  const [allIncidentTypes, setAllIncidentTypes] = useState<string[]>([]);
  const [showEdit, setShowEdit] = useState(false);
  const incidentTypes = useRecoilValue(incidentTypesAtom);
  const { sendRequest: updateDoc } = useUpdateDoc();
  const setAllReportsReset = useSetRecoilState(allReportsResetAtom);

  useEffect(() => {
    if (!incidentTypes) return;
    const tempAllIncidentTypesArray: string[] = [];
    const tempSelectedIncidentTypesArray: string[] = [];
    incidentTypes.forEach((incidentType) => {
      tempAllIncidentTypesArray.push(incidentType.short);
      if (report.selectedITs.includes(incidentType.short)) {
        tempSelectedIncidentTypesArray.push(incidentType.short);
      }
    });
    setAllIncidentTypes(tempAllIncidentTypesArray);
    setSelectedIncidentTypes(tempSelectedIncidentTypesArray);
  }, [incidentTypes, report]);

  const handleChange = (event: React.SyntheticEvent, value: string[] | null) => {
    if (!value) return;
    setSelectedIncidentTypes(value);
  };

  const handleSave = async () => {
    await updateDoc({
      col: "reports",
      id: report.id,
      data: { selectedITs: selectedIncidentTypes },
    });
    setShowEdit(false);
    setAllReportsReset((pV) => !pV);
  };
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
        <Typography variant="h5">Incident Type:</Typography>
        {!showEdit && (
          <EditIcon
            onClick={() => setShowEdit(true)}
            sx={{ ml: 2, fontSize: 18, cursor: "pointer" }}
            color="primary"
          />
        )}
        {showEdit && (
          <>
            <CheckCircleIcon
              onClick={handleSave}
              sx={{ ml: 1, fontSize: 22, cursor: "pointer" }}
              color="primary"
            />
            <CloseIcon
              onClick={() => setShowEdit(false)}
              sx={{ ml: 1, fontSize: 22, cursor: "pointer" }}
              color="error"
            />
          </>
        )}
      </Box>
      {!showEdit &&
        report.selectedITs &&
        report.selectedITs.map((s) => (
          <Chip label={s} key={s} sx={{ margin: 1, color: "white", backgroundColor: "#B930E6" }} />
        ))}
      {showEdit && (
        <Autocomplete
          multiple
          sx={{ mt: 2, mb: 2 }}
          options={allIncidentTypes}
          onChange={handleChange}
          value={selectedIncidentTypes}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Selected Incident Types"
              placeholder="Select Selected Incident Types"
            />
          )}
        />
      )}
    </>
  );
}

export default IncidentTypes;
