import {
  IncidentTypeInterface,
  StaffInterface,
  ReportInterface,
  DaysPerWeekInterface,
} from "../interfaces/interfaces";

export const parseIncidentTypeResponse = (
  response: IncidentTypeInterface[]
): IncidentTypeInterface[] =>
  response.map((incidentType: IncidentTypeInterface) => ({
    id: incidentType?.id ?? "",
    short: incidentType?.short ?? "",
    type: incidentType?.type ?? "",
  }));

export const parseStaffResponse = (response: StaffInterface[]): StaffInterface[] =>
  response.map((staff: StaffInterface) => ({
    id: staff?.id ?? "",
    displayName: staff?.displayName ?? "",
    photoURL: staff?.photoURL ?? "",
    email: staff?.email ?? "",
    uid: staff?.uid ?? "",
    permission: staff?.permission ?? "",
  }));

export const parseDaysPerWeekResponse = (
  response: DaysPerWeekInterface[]
): DaysPerWeekInterface[] =>
  response.map((daysPerWeek: DaysPerWeekInterface) => ({
    id: daysPerWeek?.id ?? "",
    days: daysPerWeek?.days ?? 0,
  }));

export const parseReportsResponse = (response: ReportInterface[]): ReportInterface[] =>
  response.map((report: ReportInterface) => ({
    id: report?.id ?? "",
    author: report?.author ?? "",
    caregiverContact: report?.caregiverContact ?? "",
    caregiverContactedNotes: report?.caregiverContactedNotes ?? "",
    howSELCanHelp: report?.howSELCanHelp ?? "",
    witnesses: report?.witnesses ?? "",
    uid: report?.uid ?? "",
    timestamp: report?.timestamp ?? null,
    students: report?.students ?? [],
    studentIDs: report?.studentIDs ?? [],
    selectedITs: report?.selectedITs ?? [],
    incidentResolution: report?.incidentResolution ?? "",
    incidentLocation: report?.incidentLocation ?? "",
    reportDescription: report?.reportDescription ?? "",
    anythingElse: report?.anythingElse ?? "",
    whenIncidentOccured: report?.whenIncidentOccured ?? null,
    imageRef: report?.imageRef ?? [],
    secondary: report?.secondary ?? false,
    createdAt: report?.createdAt ?? null,
    severity: report?.severity ?? "lowLevel",
    comments: report?.comments ?? [],
    authorEmail: report?.authorEmail ?? null,
    adminNotes: report?.adminNotes ?? "",
  }));
