import { ChangeEvent } from "react";
import { STUDENT_SEVERITY_LEVELS } from "../../libraries/objects";
import { FilterInterface } from "../../interfaces/interfaces";
import { Grid, FormControlLabel, Checkbox, Typography } from "@mui/material";

type Props = {
  filters: FilterInterface;
  setFilters: (pV: FilterInterface) => void;
};

function SelectedSeverity({ filters, setFilters }: Props) {
  const severityLevels: string[] = Object.keys(STUDENT_SEVERITY_LEVELS);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedArray = [...filters.selectedSeverity];
    if (event.target.checked) {
      selectedArray.push(event.target.name);
    } else {
      const index = selectedArray.indexOf(event.target.name);
      if (index > -1) {
        selectedArray.splice(index, 1);
      }
    }
    setFilters({ ...filters, selectedSeverity: selectedArray });
  };

  return (
    <>
      <Grid container spacing={2} sx={{ textAlign: "left" }}>
        {severityLevels.map((severityLevel) => (
          <Grid item xs={4} key={severityLevel}>
            <FormControlLabel
              control={
                <Checkbox name={severityLevel} key={severityLevel} onChange={handleChange} />
              }
              label={
                <Typography style={{ fontSize: "13px" }}>
                  {STUDENT_SEVERITY_LEVELS[severityLevel]}
                </Typography>
              }
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default SelectedSeverity;
