import { useState, useEffect, useContext } from "react";
import { ReportInterface, Comment } from "../../interfaces/interfaces";
import CommentsList from "./CommentsList";
import NewComment from "./NewComment";
import { sortArrayOfObjects } from "../../libraries/functions";
import { getDatabase, ref, onValue } from "firebase/database";
import { AuthContext } from "../../providers/AuthProvider";
import { Box } from "@mui/material";

type Props = {
  report: ReportInterface;
};

function CommentsContainer({ report }: Props) {
  const [comments, setComments] = useState<Comment[]>([]);
  const { currentUserData } = useContext(AuthContext);

  useEffect(() => {
    if (!report || !currentUserData) return;
    const db = getDatabase();
    const starCountRef = ref(db, "comments/" + report.id);
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      if (!data) return;
      const sortedComments = sortArrayOfObjects({ arr: data, field: "createdAt" });
      setComments(sortedComments);
    });
  }, [report, currentUserData]);

  return (
    <>
      <Box>
        <CommentsList report={report} comments={comments} />
        <NewComment report={report} comments={comments} />
      </Box>
    </>
  );
}

export default CommentsContainer;
