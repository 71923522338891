import { Box } from "@mui/material";
import { Comment, ReportInterface } from "../../interfaces/interfaces";
import CommentCard from "./CommentCard";

type Props = {
  report: ReportInterface;
  comments: Comment[];
};

function CommentsList({ report, comments }: Props) {
  return (
    <>
      {comments.length > 0 && (
        <Box
          sx={{ backgroundColor: "#fafafa", padding: 1, maxHeight: "500px", overflowY: "scroll" }}
        >
          {comments &&
            comments.map((comment, index) => (
              <CommentCard
                key={comment.createdAt}
                comment={comment}
                index={index}
                comments={comments}
                report={report}
              />
            ))}
        </Box>
      )}
    </>
  );
}

export default CommentsList;
