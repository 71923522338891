import { Box, Button } from "@mui/material";
import SeverityIndicator from "./SeverityIndicator";
import { ReportInterface } from "../../interfaces/interfaces";

import useUpdateDoc from "../../hooks/useUpdateDoc";
import * as Sentry from "@sentry/react";

type Props = {
  report: ReportInterface;
  setReportToDelete?: (pV: ReportInterface | null) => void;
  setSecondary: (pV: (pV: boolean) => boolean) => void;
  secondary: boolean;
};

function ReportFooter({ report, setReportToDelete, setSecondary, secondary }: Props) {
  const { sendRequest: updateDoc } = useUpdateDoc();

  const secondaryToggle = async () => {
    try {
      await updateDoc({ col: "reports", id: report.id, data: { secondary: !secondary } });
      setSecondary((pV) => !pV);
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const handleDelete = () => {
    if (!setReportToDelete) return;
    setReportToDelete(report);
  };
  return (
    <>
      <Box>
        <Button
          sx={{ mr: 1, mb: 1 }}
          size="small"
          variant="contained"
          color="error"
          onClick={handleDelete}
        >
          Delete Report
        </Button>
        {secondary ? (
          <Button
            sx={{ mr: 1, mb: 1 }}
            size="small"
            variant="contained"
            color="warning"
            onClick={secondaryToggle}
          >
            Undo Secondary{" "}
          </Button>
        ) : (
          <Button
            sx={{ mr: 1, mb: 1 }}
            size="small"
            variant="contained"
            color="warning"
            onClick={secondaryToggle}
          >
            Make Secondary
          </Button>
        )}
      </Box>
      <Box>
        <SeverityIndicator report={report} />
      </Box>
    </>
  );
}

export default ReportFooter;
