import { Typography, Container } from "@mui/material";
import PerHomeroomTable from "./PerHomeroomTable";

export default function PerHomeroomContainer() {
  return (
    <Container sx={{ backgroundColor: "#fff", pt: 2 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Reports Per Homeroom
      </Typography>
      <PerHomeroomTable />
    </Container>
  );
}
