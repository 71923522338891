import { SyntheticEvent, useState } from "react";
import { Radio, RadioGroup, FormControlLabel, FormControl } from "@mui/material";
import { ReportInterface } from "../../interfaces/interfaces";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import { SEVERITY_LEVELS } from "../../libraries/objects";

type Props = {
  report: ReportInterface;
};

type FormState = EventTarget & {
  name: string;
  value: string;
};

function SeverityIndicator({ report }: Props) {
  const { sendRequest: updateDoc } = useUpdateDoc();
  const [severity, setSeverity] = useState(report?.severity ?? "lowLevel");
  const severityLevels: string[] = Object.keys(SEVERITY_LEVELS);

  const handleChange = async (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    updateDoc({ col: "reports", id: report.id, data: { severity: formState.value } });
    setSeverity(formState.value);
  };
  return (
    <FormControl sx={{ mb: 1 }}>
      <RadioGroup row name="severity-indicator" value={severity} onChange={handleChange}>
        {severityLevels.map((severityLevel) => (
          <FormControlLabel
            value={severityLevel}
            key={severityLevel}
            control={<Radio disabled />}
            label={SEVERITY_LEVELS[severityLevel]}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default SeverityIndicator;
