import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { ReportInterface } from "../../interfaces/interfaces";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import { userReportsResetAtom, allReportsResetAtom } from "../../recoil/atoms";
import { useSetRecoilState } from "recoil";

type Props = {
  reportToDelete: ReportInterface | null;
  setReportToDelete: (pV: ReportInterface | null) => void;
};

export default function DeleteDialog({ setReportToDelete, reportToDelete }: Props) {
  const { sendRequest: updateDoc } = useUpdateDoc();
  const setUserReportsReset = useSetRecoilState(userReportsResetAtom);
  const setAllReportsReset = useSetRecoilState(allReportsResetAtom);

  const deactivateReport = async () => {
    if (!reportToDelete) return;
    await updateDoc({ col: "reports", id: reportToDelete?.id, data: { active: false } });
    setUserReportsReset((pV) => !pV);
    setAllReportsReset((pV) => !pV);
    setReportToDelete(null);
  };

  const handleClose = () => {
    setReportToDelete(null);
  };
  return (
    <>
      {reportToDelete && (
        <Dialog open={Boolean(reportToDelete)} onClose={handleClose}>
          <DialogTitle>Delete Report</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this report by {reportToDelete.author} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={deactivateReport}>Delete</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
