import { useEffect, useState } from "react";
import { Typography, Container, Grid } from "@mui/material";
import AveragePerDayChart from "./AveragePerDayChart";
import useAnnualColumns from "../../hooks/useAnnualColumns";
import { SCHOOL_YEARS } from "../../libraries/objects";
import DaysPerWeek from "./DaysPerWeek";
import { reportsPerWeekAtom, daysPerWeekAtom } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";
import { ColumnInterface } from "../../interfaces/interfaces";

export default function AveragePerDayContainer() {
  const [chartData, setChartData] = useState<{ name: string; amt: number }[] | null>(null);
  const [columns, setColumns] = useState<ColumnInterface[]>([]);
  const { generateColumns } = useAnnualColumns();

  const reportsPerWeek = useRecoilValue(reportsPerWeekAtom);
  const daysPerWeek = useRecoilValue(daysPerWeekAtom);

  useEffect(() => {
    setColumns(generateColumns({ schoolYear: SCHOOL_YEARS.SY23_24 }));
  }, [generateColumns]);

  useEffect(() => {
    if (!reportsPerWeek || !daysPerWeek) return;
    const tempChartData: { name: string; amt: number }[] = [];
    columns.forEach((column) => {
      const week = daysPerWeek.find((week) => week.id === column.field);
      const reports = reportsPerWeek[column.field] ? reportsPerWeek[column.field] : 0;
      if (week && week.days !== 0 && reports && reports !== 0) {
        tempChartData.push({
          name: column.headerName,
          amt: Number(reports / week.days),
        });
      }
    });
    setChartData(tempChartData);
  }, [reportsPerWeek, daysPerWeek, columns]);

  console.log("test");

  return (
    <Container sx={{ backgroundColor: "#fff", pt: 2 }}>
      <Grid container spacing={2}>
        <Grid item sm={10}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Average Per Day
          </Typography>
          <AveragePerDayChart chartData={chartData} />
        </Grid>
        <Grid item sm={2}>
          <DaysPerWeek weeks={columns} />
        </Grid>
      </Grid>
    </Container>
  );
}
