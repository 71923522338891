import React from "react";
import { TextField, Autocomplete } from "@mui/material";
import { useRecoilValue } from "recoil";
import { ReportInterface, StudentInterface } from "../../interfaces/interfaces";
import { studentsAtom } from "../../recoil/atoms";

type Props = {
  post: ReportInterface;
  setPost: (pV: ReportInterface) => void;
};

type StudentsForForm = {
  sid: string;
  firstName: string;
  lastName: string;
  severity: string;
};

const SelectStudents = ({ post, setPost }: Props) => {
  const students = useRecoilValue(studentsAtom);
  const onChangeHandler = (event: any, values: StudentInterface[]) => {
    const selectedStudents: StudentsForForm[] = [];
    values.forEach((s) => {
      const sObj = {
        sid: s.SID,
        firstName: s.childFirstName,
        lastName: s.childLastName,
        severity: "notCoded",
      };
      selectedStudents.push(sObj);
    });
    setPost({ ...post, students: selectedStudents });
  };

  return (
    <div>
      {students && (
        <Autocomplete
          multiple
          sx={{ mt: 2 }}
          options={students}
          getOptionLabel={(student) =>
            student.childFirstName + " " + student.childLastName + " - " + student.homeroom
          }
          onChange={onChangeHandler}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Children Involved"
              placeholder="Select Students"
            />
          )}
        />
      )}
    </div>
  );
};

export default React.memo(SelectStudents);
