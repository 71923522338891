import { useEffect, useState, SyntheticEvent } from "react";
import { useNavigate } from "react-router-dom";
import { Paper, Typography } from "@mui/material";
import { BLANK_INCIDENT_FORM } from "../../libraries/objects";
import { ReportInterface } from "../../interfaces/interfaces";
import { ImageListType } from "react-images-uploading";
import { userReportsResetAtom } from "../../recoil/atoms";
import { useSetRecoilState } from "recoil";
import useSubmitForm from "../../hooks/useSubmitForm";
import LoadingBackdrop from "../shared/LoadingBackdrop";
import Form from "./Form";
import Instructions from "./Instructions";

const FormContainer = () => {
  const blankForm = {
    ...BLANK_INCIDENT_FORM,
    students: [...BLANK_INCIDENT_FORM.students],
    studentIDs: [...BLANK_INCIDENT_FORM.studentIDs],
    selectedITs: [...BLANK_INCIDENT_FORM.selectedITs],
    imageRef: [...BLANK_INCIDENT_FORM.imageRef],
  };
  const [post, setPost] = useState<ReportInterface>(blankForm);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [images, setImages] = useState<ImageListType>([]);
  const setUserReportsReset = useSetRecoilState(userReportsResetAtom);
  const { submitForm, isLoading, error } = useSubmitForm();
  const navigate = useNavigate();

  const submit = async (event: SyntheticEvent) => {
    event.preventDefault();
    await submitForm({ images, form: post });
    setFormSubmitted(true);
    setPost({ ...blankForm });
    setUserReportsReset((pV) => !pV);
  };

  useEffect(() => {
    if (formSubmitted && !error) {
      navigate("/user-reports");
    }
  }, [formSubmitted, navigate, error]);

  return (
    <>
      {isLoading ? (
        <LoadingBackdrop open={isLoading} message="Submitting Your Report" />
      ) : (
        <Paper sx={{ padding: 2, mt: 3 }}>
          <Typography align="center" variant="h2" sx={{ mb: 2 }}>
            Incident Report Form
          </Typography>
          <Instructions />
          <Form
            post={post}
            setPost={setPost}
            images={images}
            setImages={setImages}
            submit={submit}
          />
        </Paper>
      )}
    </>
  );
};

export default FormContainer;
