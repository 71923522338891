import {
  allReportsAtom,
  daysPerWeekAtom,
  filteredReportsAtom,
  filtersAtom,
  homeroomsAtom,
  staffAtom,
  studentsAtom,
  totalReportsToShowAtom,
  villagesAtom,
} from "./atoms";
import { filterResults, createDateField } from "../libraries/functions";
import { GetRecoilValue } from "recoil";
import { PART_OF_DAY_ARRAY, PART_OF_DAY } from "../libraries/objects";
import {
  FilterInterface,
  ReportInterface,
  StaffInterface,
  StudentInterface,
} from "../interfaces/interfaces";

type Props = {
  get: GetRecoilValue;
};

type BySeverityType = {
  filteredReports: ReportInterface[];
  filters: FilterInterface;
  student: StudentInterface;
};

const filterStudentReportsBySeverity = ({ filteredReports, filters, student }: BySeverityType) => {
  const studentReports: ReportInterface[] = [];
  const filteredByStudentReports = filteredReports?.filter(
    (report) => !report.secondary && report.studentIDs?.includes(student.SID)
  );
  if (filteredByStudentReports.length > 0 && filters.selectedSeverity.length > 0) {
    filteredByStudentReports.forEach((report) => {
      if (report.students && report.students.length > 0 && report.students[0].severity) {
        report.students.forEach((s) => {
          if (s.sid === student.SID && filters.selectedSeverity.includes(s.severity)) {
            studentReports.push(report);
          }
        });
      } else {
        studentReports.push(report);
      }
    });
  } else {
    studentReports.push(...filteredByStudentReports);
  }
  return studentReports;
};

export const filteredReportsGetter = ({ get }: Props) => {
  const allReports = get(allReportsAtom);
  const filters = get(filtersAtom);
  if (!allReports) return null;
  const filteredReports = allReports.filter((report) => filterResults(report, filters));
  return filteredReports;
};

export const reportsToShowGetter = ({ get }: Props) => {
  const filteredReports = get(filteredReportsAtom);
  const totalReportsToShow = get(totalReportsToShowAtom);
  if (!filteredReports) return [];
  return filteredReports.slice(0, totalReportsToShow);
};

export const reportsPerChildGetter = ({ get }: Props) => {
  const filteredReports = get(filteredReportsAtom);
  const students = get(studentsAtom);
  const filters = get(filtersAtom);
  if (!filteredReports) return null;
  const finalTempArray: { [key: string]: any }[] = [];
  students.forEach((student) => {
    if (!student.SID) return;
    const studentTempObject: { [key: string]: any } = {
      SID: student.SID,
      id: student.SID,
      firstName: student.childFirstName,
      lastName: student.childLastName,
      homeroom: student.homeroom,
      count: 0,
    };
    
    const studentReports = filterStudentReportsBySeverity({ filteredReports, filters, student });
    if (studentReports) {
      studentReports.forEach((report) => {
        const { dateString, yearNumber, weekNumber } = createDateField(report);
        if ((yearNumber === 2022 && weekNumber > 28) || yearNumber === 2023) {
          studentTempObject.count++;
          if (studentTempObject[dateString]) {
            studentTempObject[dateString] = Number(studentTempObject[dateString]) + 1;
          } else {
            studentTempObject[dateString] = 1;
          }
        }
      });
    }
    finalTempArray.push(studentTempObject);
  });
  finalTempArray.sort((a, b) => (b.count > a.count ? 1 : -1));
  return finalTempArray;
};

export const reportsPerTeacherGetter = ({ get }: Props) => {
  const filteredReports = get(filteredReportsAtom);
  const staff = get(staffAtom);
  if (!filteredReports) return null;
  const finalTempArray: { [key: string]: any }[] = [];
  staff.forEach((staffMember) => {
    const staffTempObject: { [key: string]: any } = {
      id: staffMember.id,
      name: staffMember.displayName,
      count: 0,
    };
    const staffReports = filteredReports?.filter(
      (report) => !report.secondary && report.uid === staffMember.id
    );
    if (staffReports) {
      staffReports.forEach((report) => {
        const { dateString, yearNumber, weekNumber } = createDateField(report);
        if ((yearNumber === 2022 && weekNumber > 28) || yearNumber === 2023) {
          staffTempObject.count++;
          if (staffTempObject[dateString]) {
            staffTempObject[dateString] = Number(staffTempObject[dateString]) + 1;
          } else {
            staffTempObject[dateString] = 1;
          }
        }
      });
    }
    finalTempArray.push(staffTempObject);
  });

  finalTempArray.sort((a, b) => (b.count > a.count ? 1 : -1));
  return finalTempArray;
};

export const reportsPerHomeroomGetter = ({ get }: Props) => {
  const filteredReports = get(filteredReportsAtom);
  const homerooms = get(homeroomsAtom);
  const students = get(studentsAtom);
  const daysPerWeek = get(daysPerWeekAtom);
  const filters = get(filtersAtom);
  if (!filteredReports || !homerooms || !students || !daysPerWeek) return null;
  const homeroomCountObj: { [key: string]: { [key: string]: number } } = {};
  homerooms.forEach((homeroom) => {
    homeroomCountObj[homeroom.id] = { count: 0 };
  });
  const finalTempArray: { [key: string]: any }[] = [];
  students.forEach((student) => {
    const studentReports = filterStudentReportsBySeverity({ filteredReports, filters, student });
    if (studentReports) {
      studentReports.forEach((report) => {
        const { dateString, yearNumber, weekNumber } = createDateField(report);
        if ((yearNumber === 2022 && weekNumber > 28) || yearNumber === 2023) {
          homeroomCountObj[student.homeroomId].count++;
          if (homeroomCountObj[student.homeroomId][dateString]) {
            homeroomCountObj[student.homeroomId][dateString] =
              Number(homeroomCountObj[student.homeroomId][dateString]) + 1;
          } else {
            homeroomCountObj[student.homeroomId][dateString] = 1;
          }
        }
      });
    }
  });
  homerooms.forEach((homeroom) => {
    const tempObj = {
      name: homeroom.name,
      id: homeroom.id,
      ...homeroomCountObj[homeroom.id],
    };
    finalTempArray.push(tempObj);
  });
  finalTempArray.sort((a, b) => (b.count > a.count ? 1 : -1));
  const totalsRowCount: { [key: string]: number } = {};
  daysPerWeek.forEach((dayPerWeek) => {
    totalsRowCount[dayPerWeek.id] = 0;
    finalTempArray.forEach((row, index) => {
      row[dayPerWeek.id] && (totalsRowCount[dayPerWeek.id] += row[dayPerWeek.id]);
    });
  });
  finalTempArray.unshift({
    name: "Total",
    id: "Total",
    ...totalsRowCount,
  });
  return finalTempArray;
};

export const reportsPerWeekGetter = ({ get }: Props) => {
  const filteredReports = get(filteredReportsAtom);
  const notSecondaryReports = filteredReports?.filter((report) => !report.secondary);
  const perWeekObj: { [key: string]: number } = {};
  if (notSecondaryReports) {
    notSecondaryReports.forEach((report) => {
      const { dateString, yearNumber, weekNumber } = createDateField(report);
      if ((yearNumber === 2022 && weekNumber > 28) || yearNumber === 2023) {
        if (perWeekObj[dateString]) {
          perWeekObj[dateString] = Number(perWeekObj[dateString]) + 1;
        } else {
          perWeekObj[dateString] = 1;
        }
      }
    });
    return perWeekObj;
  }
};

export const perVillageGetter = ({ get }: Props) => {
  const filteredReports = get(filteredReportsAtom);
  const villages = get(villagesAtom);
  const homerooms = get(homeroomsAtom);
  const students = get(studentsAtom);
  const daysPerWeek = get(daysPerWeekAtom);
  const filters = get(filtersAtom);
  if (!filteredReports || !villages || !homerooms || !students || !daysPerWeek) return null;
  const villagesCountObj: { [key: string]: { [key: string]: number } } = {};
  villages.forEach((village) => {
    villagesCountObj[village.id] = { count: 0 };
  });
  const finalTempArray: { [key: string]: any }[] = [];
  students.forEach((student) => {
    const studentReports = filterStudentReportsBySeverity({ filteredReports, filters, student });
    if (studentReports.length > 0) {
      studentReports.forEach((report) => {
        const { dateString, yearNumber, weekNumber } = createDateField(report);
        if ((yearNumber === 2022 && weekNumber > 28) || yearNumber === 2023) {
          const currentHomeroom = homerooms.find((homeroom) => homeroom.id === student.homeroomId);
          if (!currentHomeroom) return;
          villagesCountObj[currentHomeroom.villageId].count++;
          if (villagesCountObj[currentHomeroom.villageId][dateString]) {
            villagesCountObj[currentHomeroom.villageId][dateString] =
              Number(villagesCountObj[currentHomeroom.villageId][dateString]) + 1;
          } else {
            villagesCountObj[currentHomeroom.villageId][dateString] = 1;
          }
        }
      });
    }
  });
  villages.forEach((village) => {
    const tempObj = {
      name: village.name,
      id: village.id,
      ...villagesCountObj[village.id],
    };
    finalTempArray.push(tempObj);
  });
  finalTempArray.sort((a, b) => (b.count > a.count ? 1 : -1));
  const totalsRowCount: { [key: string]: number } = {};
  daysPerWeek.forEach((dayPerWeek) => {
    totalsRowCount[dayPerWeek.id] = 0;
    finalTempArray.forEach((row, index) => {
      if (finalTempArray[index][dayPerWeek.id] && dayPerWeek.days !== 0) {
        finalTempArray[index][dayPerWeek.id] =
          (finalTempArray[index][dayPerWeek.id] / dayPerWeek.days) * 5;
      }
      row[dayPerWeek.id] && (totalsRowCount[dayPerWeek.id] += row[dayPerWeek.id]);
    });
  });
  finalTempArray.unshift({
    name: "Total",
    id: "Total",
    ...totalsRowCount,
  });
  return finalTempArray;
};

export const reportsPerLocationGetter = ({ get }: Props) => {
  const filteredReports = get(filteredReportsAtom);
  const students = get(studentsAtom);
  const daysPerWeek = get(daysPerWeekAtom);
  const filters = get(filtersAtom);
  if (!filteredReports || !students || !daysPerWeek) return null;
  const locationCountObj: { [key: string]: { [key: string]: number } } = {};
  PART_OF_DAY_ARRAY.forEach((part) => {
    locationCountObj[part] = { count: 0 };
  });
  const finalTempArray: { [key: string]: any }[] = [];
  students.forEach((student) => {
    const studentReports = filterStudentReportsBySeverity({ filteredReports, filters, student });
    if (studentReports) {
      studentReports.forEach((report) => {
        if (!report.incidentLocation || !locationCountObj[report.incidentLocation]) return;
        const { dateString, yearNumber, weekNumber } = createDateField(report);
        if ((yearNumber === 2022 && weekNumber > 28) || yearNumber === 2023) {
          locationCountObj[report.incidentLocation].count++;
          if (locationCountObj[report.incidentLocation][dateString]) {
            locationCountObj[report.incidentLocation][dateString] =
              Number(locationCountObj[report.incidentLocation][dateString]) + 1;
          } else {
            locationCountObj[report.incidentLocation][dateString] = 1;
          }
        }
      });
    }
  });
  PART_OF_DAY_ARRAY.forEach((part) => {
    const tempObj = {
      name: PART_OF_DAY[part],
      id: part,
      ...locationCountObj[part],
    };
    finalTempArray.push(tempObj);
  });
  finalTempArray.sort((a, b) => (b.count > a.count ? 1 : -1));
  const totalsRowCount: { [key: string]: number } = {};
  daysPerWeek.forEach((dayPerWeek) => {
    totalsRowCount[dayPerWeek.id] = 0;
    finalTempArray.forEach((row, index) => {
      row[dayPerWeek.id] && (totalsRowCount[dayPerWeek.id] += row[dayPerWeek.id]);
    });
  });
  finalTempArray.unshift({
    name: "Total",
    id: "Total",
    ...totalsRowCount,
  });
  return finalTempArray;
};

export const staffObjGetter = ({ get }: Props) => {
  const staff = get(staffAtom);
  if (!staff) return null;
  const tempObj: { [key: string]: StaffInterface } = {};
  staff.forEach((staffMember) => {
    tempObj[staffMember.email] = staffMember;
  });
  return tempObj;
};

export const studentsObjGetter = ({ get }: Props) => {
  const students = get(studentsAtom);
  if (!students) return null;
  const tempObj: { [key: string]: StudentInterface } = {};
  students.forEach((student) => {
    tempObj[student.SID] = student;
  });
  return tempObj;
};
