import PerChildContainer from "./PerChildContainer";
import PerTeacherContainer from "./PerTeacherContainer";
import PerHomeroomContainer from "./PerHomeroomContainer";
import AveragePerDayContainer from "./AveragePerDayContainer";
import AllLogsContainer from "./AllLogsContainer";
import { ROLLUP_VIEWS } from "../../libraries/objects";
import { Box } from "@mui/material";
import PerVillageContainer from "./PerVillageContainer";
import PerLocationContainer from "./PerLocationContainer";

type Props = {
  activeRollupView: string;
};

function Rollups({ activeRollupView }: Props) {
  return (
    <Box sx={{ padding: 2 }}>
      {activeRollupView === ROLLUP_VIEWS.PER_CHILD && <PerChildContainer />}
      {activeRollupView === ROLLUP_VIEWS.PER_TEACHER && <PerTeacherContainer />}
      {activeRollupView === ROLLUP_VIEWS.PER_HOMEROOM && <PerHomeroomContainer />}
      {activeRollupView === ROLLUP_VIEWS.ALL_LOGS && <AllLogsContainer />}
      {activeRollupView === ROLLUP_VIEWS.PER_VILLAGE && <PerVillageContainer />}
      {activeRollupView === ROLLUP_VIEWS.AVERAGE_PER_DAY && <AveragePerDayContainer />}
      {activeRollupView === ROLLUP_VIEWS.PER_LOCATION && <PerLocationContainer />}
    </Box>
  );
}

export default Rollups;
