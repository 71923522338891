import { ROLLUP_VIEWS } from "../../libraries/objects";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Button } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type Props = {
  setActiveRollupView: (newValue: string) => void;
};

export default function MobileAccordionMenu({ setActiveRollupView }: Props) {
  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">Rollup Options</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Button
            fullWidth
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => {
              setActiveRollupView(ROLLUP_VIEWS.PER_CHILD);
            }}
          >
            Per Child
          </Button>
          <Button
            fullWidth
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => {
              setActiveRollupView(ROLLUP_VIEWS.PER_TEACHER);
            }}
          >
            Per Teacher
          </Button>
          <Button
            fullWidth
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => {
              setActiveRollupView(ROLLUP_VIEWS.PER_HOMEROOM);
            }}
          >
            Per Homeroom
          </Button>
          <Button
            fullWidth
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => {
              setActiveRollupView(ROLLUP_VIEWS.AVERAGE_PER_DAY);
            }}
          >
            Avg Per Day
          </Button>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
