import { TextField, Box } from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { FilterInterface } from "../../interfaces/interfaces";

type Props = {
  filters: FilterInterface;
  setFilters: (pV: FilterInterface) => void;
};

export default function ReportDateRangePicker({ filters, setFilters }: Props) {
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <DateRangePicker
        startText="Reports on or After"
        endText="Reports Before"
        value={filters.dateRange}
        onChange={(dateRange: any) => {
          setFilters({ ...filters, dateRange });
        }}
        renderInput={(startProps: any, endProps: any) => (
          <>
            <TextField {...startProps} />
            <Box sx={{ mx: 2 }}> to </Box>
            <TextField {...endProps} />
          </>
        )}
      />
    </LocalizationProvider>
  );
}
