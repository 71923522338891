import { Box, Button, TextField, Autocomplete } from "@mui/material";
import React, { useContext, useState, useRef, useEffect } from "react";
import { Comment, ReportInterface, StaffInterface } from "../../interfaces/interfaces";
import { AuthContext } from "../../providers/AuthProvider";
import { getDatabase, ref, set } from "firebase/database";
import useAddDoc from "../../hooks/useAddDoc";
import { Timestamp } from "firebase/firestore";
import { formatCommentEmail } from "../../libraries/functions";
import { staffAtom } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";

type Props = {
  report: ReportInterface;
  comments: Comment[];
};

function NewComment({ report, comments }: Props) {
  const [text, setText] = useState("");
  const { currentUserData } = useContext(AuthContext);
  const allStaff = useRecoilValue(staffAtom);
  const { sendRequest: addDoc } = useAddDoc();
  const textRef = useRef<HTMLInputElement>();
  const [selectedStaff, setSelectedStaff] = useState<StaffInterface[]>([]);

  function writeUserData(data: Comment[]) {
    if (!currentUserData) return;
    const db = getDatabase();
    set(ref(db, "comments/" + report.id), data);
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const writeToMail = (text: string) => {
    if (!currentUserData) return;
    const emails: string[] = [];
    if (report.authorEmail && currentUserData.email !== report.authorEmail) {
      emails.push(report.authorEmail);
    }
    const message = formatCommentEmail({
      report: report,
      displayName: currentUserData.displayName,
      text: text,
    });

    comments.forEach((comment) => {
      if (!emails.includes(comment.authorEmail) && comment.authorEmail !== currentUserData.email) {
        emails.push(comment.authorEmail);
      }
      if (comment.taggedEmails) {
        comment.taggedEmails.forEach((taggedEmail) => {
          if (!emails.includes(taggedEmail) && taggedEmail !== currentUserData.email) {
            emails.push(taggedEmail);
          }
        });
      }
    });
    emails.forEach((email) => {
      addDoc({
        col: "mail",
        data: {
          to: email,
          message: {
            html: message,
            subject: "New Comment in Incident Reports",
          },
          createdAt: Timestamp.now(),
        },
      });
    });
  };

  const handleSave = async () => {
    if (!currentUserData) return;
    const commentsArray = [...comments];
    const currentDate = new Date();
    const data: Comment = {
      text: text,
      authorName: currentUserData.displayName,
      createdAt: currentDate.toISOString(),
      lastUpdated: currentDate.toISOString(),
      readEmails: [currentUserData.email],
      avatarURL: currentUserData.photoURL,
      authorEmail: currentUserData.email,
      taggedEmails: selectedStaff.map((staffMember) => staffMember.email),
    };
    commentsArray.push(data);
    writeUserData(commentsArray);
    writeToMail(text);
    setText("");
    setSelectedStaff([]);
  };

  const handleStaffChange = (event: React.SyntheticEvent, value: StaffInterface[] | null) => {
    setSelectedStaff(value || []);
  };

  return (
    <>
      <Box sx={{ textAlign: "right", padding: 1 }}>
        <TextField
          multiline
          rows={2}
          fullWidth
          onChange={handleChange}
          value={text}
          inputRef={textRef}
        />
        <Autocomplete
          id="staff-tags"
          options={allStaff}
          multiple
          sx={{ mt: 2 }}
          fullWidth
          getOptionLabel={(s) => `${s.displayName}`}
          onChange={handleStaffChange}
          value={selectedStaff}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Tag" placeholder="Select Staff" />
          )}
        />
        <Button sx={{ mt: 1 }} variant="outlined" color="primary" onClick={handleSave}>
          Submit Comment
        </Button>
      </Box>
    </>
  );
}

export default NewComment;
