import { Grid, Typography, Box, CircularProgress } from "@mui/material";
import { userReportsAtom } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";
import Report from "./Report";

const UserReportsContainer = () => {
  const reports = useRecoilValue(userReportsAtom);

  return (
    <>
      {!reports ? (
        <Box sx={{ mt: 8, textAlign: "center", pt: 8 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={3} sx={{ pt: 2 }}>
          <Grid item xs={12}>
            <Typography variant="h4">My Reports</Typography>
          </Grid>
          {reports.length === 0 && (
            <Box sx={{ textAlign: "center", pt: 8 }}>
              <Typography variant="h2">You Have Not Submitted Any Reports</Typography>
            </Box>
          )}
          {reports.map((report) => (
            <Grid item xs={12} key={report.id}>
              <Report admin={false} report={report} />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default UserReportsContainer;
