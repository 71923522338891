import { SyntheticEvent } from "react";
import { FormGroup, Typography, Grid, FormControlLabel, Checkbox } from "@mui/material";
import { FilterInterface } from "../../interfaces/interfaces";
import { incidentTypesAtom } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";

type Props = {
  filters: FilterInterface;
  setFilters: (pV: FilterInterface) => void;
};

type FormState = EventTarget & {
  name: string;
  value: string;
  checked: boolean;
};

export default function IncidentTypeFilter({ filters, setFilters }: Props) {
  const incidentTypes = useRecoilValue(incidentTypesAtom);

  const handleChange = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    const selectedArray = [...filters.selectedITs];
    if (formState.checked) {
      selectedArray.push(formState.name);
    } else {
      const index = selectedArray.indexOf(formState.name);
      if (index > -1) {
        selectedArray.splice(index, 1);
      }
    }
    setFilters({ ...filters, selectedITs: selectedArray });
  };

  return (
    <FormGroup>
      <Typography variant="body1" style={{ marginTop: "1em" }}>
        Type of incident(s) You Want to See.
      </Typography>
      <Typography variant="body2" style={{ marginBottom: "1em" }}>
        If multiple boxes are selected, it will show all results with any of the selected incident
        types.
      </Typography>
      {incidentTypes && (
        <Grid container spacing={2} sx={{ textAlign: "left" }}>
          {incidentTypes.map((i) => (
            <Grid item xs={12} sm={6} key={i.id}>
              <FormControlLabel
                control={<Checkbox name={i.short} key={i.id} onChange={handleChange} />}
                label={<Typography style={{ fontSize: "13px" }}>{i.type}</Typography>}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </FormGroup>
  );
}
