import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Typography,
  TextField,
} from "@mui/material";
import { ReportInterface } from "../../interfaces/interfaces";
import { CONTACT_OPTIONS_ARRAY } from "../../libraries/objects";

type Props = {
  post: ReportInterface;
  setPost: (pV: ReportInterface) => void;
};

export default function CaregiversContacted({ post, setPost }: Props) {
  return (
    <>
      <FormControl component="fieldset">
        <Typography variant="body1" style={{ marginBottom: "1em", marginTop: "1em" }}>
          Have you contacted the caregivers about this incident or verified that someone else will?
        </Typography>
        <RadioGroup
          aria-label="Caregivers Contacted?"
          defaultValue="noContact"
          name="radio-buttons-group"
          onChange={(e) => {
            setPost({ ...post, caregiverContact: e.target.value });
          }}
        >
          {CONTACT_OPTIONS_ARRAY.map((option) => (
            <FormControlLabel
              value={option.key}
              key={option.key}
              control={<Radio />}
              label={option.value}
            />
          ))}
        </RadioGroup>
      </FormControl>

      {post.caregiverContact && post.caregiverContact === "yesContact" && (
        <>
          <Typography sx={{ mt: 2 }}>
            Who did you contact and how did you contact them? Please share if spoke with them,
            communicated back and forth, or simply sent a message.
          </Typography>

          <TextField
            fullWidth
            sx={{ mt: 2 }}
            id="caregiverContactedNotesYes"
            name="caregiverContactedNotesYes"
            label="Please Share Contact Details"
            multiline
            rows={4}
            onChange={(e) => setPost({ ...post, caregiverContactedNotes: e.target.value })}
          />
        </>
      )}
      {post.caregiverContact && post.caregiverContact === "verifiedSomeoneElse" && (
        <>
          <Typography sx={{ mt: 2 }}>
            Who did you verify would be communicating with the caregiver about this incident so we
            can follow-up with them.
          </Typography>

          <TextField
            fullWidth
            sx={{ mt: 2 }}
            id="caregiverContactedNotesverifiedSomeoneElse"
            name="caregiverContactedNotesverifiedSomeoneElse"
            label="Please Share Contact Details"
            multiline
            rows={4}
            onChange={(e) => setPost({ ...post, caregiverContactedNotes: e.target.value })}
          />
        </>
      )}
      {post.caregiverContact && post.caregiverContact === "verifiedNoCommsNeeded" && (
        <>
          <Typography sx={{ mt: 2 }}>
            Which school leader or manager confirmed you do not need to communicate with a caregiver
            about this incident?
          </Typography>

          <TextField
            fullWidth
            sx={{ mt: 2 }}
            id="caregiverContactedNotesverifiedNoCommsNeeded"
            name="caregiverContactedNotesverifiedNoCommsNeeded"
            label="Please Share Contact Details"
            multiline
            rows={4}
            onChange={(e) => setPost({ ...post, caregiverContactedNotes: e.target.value })}
          />
        </>
      )}
    </>
  );
}
