import { useEffect } from "react";
import { Paper, Grid, Typography, Box, Divider } from "@mui/material";
import { useRecoilState, useSetRecoilState } from "recoil";
import { filtersAtom, totalReportsToShowAtom } from "../../recoil/atoms";
import SelectStudents from "../Filters/SelectStudents";
import SelectStaff from "../Filters/SelectStaff";
import CaregiversContactedFilter from "../Filters/CaregiversContactedFilter";
import ReportDateRangePicker from "../Filters/ReportDateRangePicker";
import IncidentTypeFilter from "../Filters/IncidentTypeFilter";
import SelectedSeverity from "../Filters/SelectedSeverity";
import { FilterInterface } from "../../interfaces/interfaces";

export default function Filter() {
  const [filters, setFilters] = useRecoilState<FilterInterface>(filtersAtom);
  const setTotalReportsToShow = useSetRecoilState(totalReportsToShowAtom);

  useEffect(() => {
    setTotalReportsToShow(10);
  }, [filters, setTotalReportsToShow]);

  return (
    <Paper sx={{ mt: 4, mb: 4, padding: 2, textAlign: "center" }}>
      {filters && (
        <>
          <Typography variant="h2">Filters</Typography>
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12} sm={12}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <ReportDateRangePicker filters={filters} setFilters={setFilters} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <CaregiversContactedFilter filters={filters} setFilters={setFilters} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SelectStudents filters={filters} setFilters={setFilters}></SelectStudents>
            </Grid>
            <Grid item xs={12} sm={4}>
              <SelectStaff filters={filters} setFilters={setFilters}></SelectStaff>
            </Grid>
            <Grid item xs={12} sm={12}>
              <IncidentTypeFilter filters={filters} setFilters={setFilters}></IncidentTypeFilter>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={12}>
              <SelectedSeverity filters={filters} setFilters={setFilters} />
            </Grid>
          </Grid>
        </>
      )}
    </Paper>
  );
}
