import { Box, Typography } from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";
import { Comment, ReportInterface } from "../interfaces/interfaces";
import ReportForPrint from "./ReportForPrint";
import { useEffect, useState } from "react";
import { DataSnapshot, get, getDatabase, onValue, ref } from "firebase/database";
import { sortArrayOfObjects } from "../libraries/functions";

type Props = {
  reportsToShow: ReportInterface[];
};

function PrintTable({ reportsToShow }: Props) {
  const [reports, setReports] = useState<ReportInterface[]>([]);

  useEffect(() => {
    const setComments = async () => {
      const tempReports = [...reportsToShow];
      reportsToShow.forEach(async (report, index) => {
        const db = getDatabase();
        const snapshot = await get(ref(db, "comments/" + report.id));
        const results = snapshot.val();
        if (!results) return;
        const sortedComments: Comment[] = sortArrayOfObjects({
          arr: snapshot.val(),
          field: "createdAt",
        });
        if (!tempReports[index].comments) return;
        tempReports[index]?.comments?.push(...sortedComments);
      });
      setReports(tempReports);
    };
    setComments();
  }, []);

  return (
    <>
      <Header />
      <Footer />

      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <td>
              <div className="page-header-space"></div>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <Typography variant="h2" sx={{ textAlign: "center" }}>
                Incident Reports
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              {reports.map((report) => (
                <ReportForPrint report={report} key={report.id} />
              ))}
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td>
              <div className="page-footer-space"></div>
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
}

export default PrintTable;
