import { Grid, Typography } from "@mui/material";
import { allReportsAtom, staffAtom } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";
import StaffCard from "./StaffCard";

const Staff = () => {
  const staff = useRecoilValue(staffAtom);
  

  return (
    <>
      <Typography align="center" variant="h2" sx={{ mb: 2 }}>
        Staff
      </Typography>
      <Grid container spacing={3}>
        {staff &&
          staff.map((staffMember) => (
            <Grid item xs={12} sm={6} md={4} key={staffMember.id}>
              <StaffCard staffMember={staffMember} key={staffMember.id} />
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default Staff;
