import { Box, List, ListItem, ListItemButton, ListItemText, Divider } from "@mui/material";
import { ROLLUP_VIEWS } from "../../libraries/objects";

type Props = {
  setActiveRollupView: (newValue: string) => void;
};

export default function SideMenu({ setActiveRollupView }: Props) {
  return (
    <Box sx={{ width: "100%", backgroundColor: "#eee", minHeight: "100VH" }}>
      <nav>
        <List>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                setActiveRollupView(ROLLUP_VIEWS.PER_CHILD);
              }}
            >
              <ListItemText primary="Per Child" />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                setActiveRollupView(ROLLUP_VIEWS.PER_TEACHER);
              }}
            >
              <ListItemText primary="Per Staff" />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                setActiveRollupView(ROLLUP_VIEWS.PER_HOMEROOM);
              }}
            >
              <ListItemText primary="Per Homeroom" />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                setActiveRollupView(ROLLUP_VIEWS.PER_VILLAGE);
              }}
            >
              <ListItemText primary="Per Village" />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                setActiveRollupView(ROLLUP_VIEWS.AVERAGE_PER_DAY);
              }}
            >
              <ListItemText primary="Average Per Day" />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                setActiveRollupView(ROLLUP_VIEWS.PER_LOCATION);
              }}
            >
              <ListItemText primary="Per Location" />
            </ListItemButton>
          </ListItem>
          <Divider />
        </List>
      </nav>
    </Box>
  );
}
