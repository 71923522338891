import { atom, selector } from "recoil";
import { BLANK_FILTER_OBJ } from "../libraries/objects";
import {
  IncidentTypeInterface,
  StaffInterface,
  StudentInterface,
  HomeroomInterface,
  ReportInterface,
  FilterInterface,
  DaysPerWeekInterface,
  VillagesInterface,
} from "../interfaces/interfaces";

import {
  filteredReportsGetter,
  reportsPerChildGetter,
  reportsToShowGetter,
  reportsPerTeacherGetter,
  reportsPerHomeroomGetter,
  reportsPerWeekGetter,
  perVillageGetter,
  reportsPerLocationGetter,
  staffObjGetter,
  studentsObjGetter,
} from "./selectors";

//****** STAFF ATOMS ******//

export const staffAtom = atom<StaffInterface[]>({
  key: "staff",
  default: [],
});

export const staffObjAtom = selector({
  key: "staffObj",
  get: staffObjGetter,
});

export const staffResetAtom = atom({
  key: "staffReset",
  default: false,
});

//****** INCIDENT REPORT ATOMS ******//

export const incidentTypesAtom = atom<IncidentTypeInterface[]>({
  key: "incidentTypes",
  default: [],
});

export const userReportsAtom = atom<ReportInterface[] | null>({
  key: "userReports",
  default: null,
});

export const userReportsResetAtom = atom({
  key: "userReportsReset",
  default: false,
});

export const allReportsAtom = atom<ReportInterface[]>({
  key: "allReports",
  default: [],
});

export const allReportsLoadingAtom = atom({
  key: "allReportsLoading",
  default: false,
});

export const allReportsResetAtom = atom({
  key: "allReportsReset",
  default: false,
});

export const filteredReportsAtom = selector<ReportInterface[] | null>({
  key: "filteredReports",
  get: filteredReportsGetter,
});

export const reportsToShowAtom = selector<ReportInterface[]>({
  key: "reportsToShow",
  get: reportsToShowGetter,
});

export const filtersAtom = atom<FilterInterface>({
  key: "filters",
  default: BLANK_FILTER_OBJ,
});

export const totalReportsToShowAtom = atom({
  key: "totalReportsToShow",
  default: 10,
});

//****** HOMEROOM ATOMS ******//

export const homeroomsAtom = atom<HomeroomInterface[]>({
  key: "homerooms",
  default: [],
});

//****** STUDENT ATOMS ******//

export const studentsAtom = atom<StudentInterface[]>({
  key: "students",
  default: [],
});

export const studentsObjAtom = selector({
  key: "studentsObj",
  get: studentsObjGetter,
});

export const studentsSPEDObjectAtom = atom<{ [key: string]: string } | null>({
  key: "studentsSPEDObject",
  default: null,
});

//****** ROLLUPS ATOMS ******//

export const reportsPerChildAtom = selector({
  key: "reportsPerChild",
  get: reportsPerChildGetter,
});

export const reportsPerTeacherAtom = selector({
  key: "reportsPerTeacher",
  get: reportsPerTeacherGetter,
});

export const reportsPerHomeroomAtom = selector({
  key: "reportsPerHomeroom",
  get: reportsPerHomeroomGetter,
});

export const daysPerWeekAtom = atom<DaysPerWeekInterface[]>({
  key: "daysPerWeek",
  default: [],
});

export const daysPerWeekResetAtom = atom({
  key: "daysPerWeekReset",
  default: false,
});

export const reportsPerWeekAtom = selector({
  key: "reportsPerWeek",
  get: reportsPerWeekGetter,
});

export const perVillageAtom = selector({
  key: "perVillage",
  get: perVillageGetter,
});

export const reportsPerLocationAtom = selector({
  key: "reportsPerLocation",
  get: reportsPerLocationGetter,
});

export const villagesAtom = atom<VillagesInterface[]>({
  key: "villages",
  default: [],
});
