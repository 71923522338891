import React, { useState, useEffect } from "react";
import {
  allReportsResetAtom,
  studentsAtom,
  studentsObjAtom,
  studentsSPEDObjectAtom,
} from "../../recoil/atoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Typography, Chip, Autocomplete, TextField, Box, Grid } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ReportInterface } from "../../interfaces/interfaces";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import StudentSeverityIndicator from "./StudentSeverityIndicator";

type Props = {
  report: ReportInterface;
  admin: boolean;
};

type StudentsForSelect = {
  sid: string;
  firstName: string;
  lastName: string;
  severity: string;
};

function StudentsInvolved({ report, admin }: Props) {
  const studentsSPEDObject = useRecoilValue(studentsSPEDObjectAtom);
  const [showEdit, setShowEdit] = useState(false);
  const [studentsForSelect, setStudentsForSelect] = useState<StudentsForSelect[]>([]);
  const [selectedStudents, setSelectedStudents] = useState<StudentsForSelect[]>(report.students);
  const { sendRequest: updateDoc } = useUpdateDoc();
  const students = useRecoilValue(studentsAtom);
  const studentsObj = useRecoilValue(studentsObjAtom);
  const setAllReportsReset = useSetRecoilState(allReportsResetAtom);

  useEffect(() => {
    if (!students) return;
    setStudentsForSelect(
      students.map((student) => ({
        sid: student.SID,
        firstName: student.childFirstName,
        lastName: student.childLastName,
        severity: "notCoded",
      }))
    );
  }, [students]);

  const renderSpedStatus = (studentId: string) => {
    if (!admin || !studentsSPEDObject) return "";
    const status = studentsSPEDObject[studentId];
    if (status === "Not In Special Education") return "";
    else return status;
  };

  const handleChange = (event: any, values: StudentsForSelect[]) => {
    setSelectedStudents(values);
  };

  const handleSave = async () => {
    await updateDoc({
      col: "reports",
      id: report.id,
      data: {
        students: selectedStudents,
        studentIDs: selectedStudents.map((student) => student.sid),
      },
    });
    setShowEdit(false);
    setAllReportsReset((pV) => !pV);
  };
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
        <Typography variant="h5">Students Involved:</Typography>
        {!showEdit && (
          <EditIcon
            onClick={() => setShowEdit(true)}
            sx={{ ml: 2, fontSize: 18, cursor: "pointer" }}
            color="primary"
          />
        )}
        {showEdit && (
          <CheckCircleIcon
            onClick={handleSave}
            sx={{ ml: 1, fontSize: 22, cursor: "pointer" }}
            color="primary"
          />
        )}
        {showEdit && (
          <CloseIcon
            onClick={() => setShowEdit(false)}
            sx={{ ml: 1, fontSize: 22, cursor: "pointer" }}
            color="error"
          />
        )}
      </Box>
      {/* {!showEdit &&
        report.students &&
        report.students.map((s) => (
          <Chip
            label={s.firstName + " " + s.lastName + " " + renderSpedStatus(s.sid)}
            key={s.sid}
            sx={{ margin: 1, color: "white", backgroundColor: "#26C6F5" }}
          />
        ))} */}
      <Grid container spacing={2} alignItems={"center"}>
        {!showEdit &&
          report.students &&
          report.students.map((s) => (
            <React.Fragment key={s.sid}>
              <Grid item xs={3}>
                <Chip
                  label={s.firstName + " " + s.lastName + " " + renderSpedStatus(s.sid)}
                  key={s.sid}
                  sx={{ margin: 1, color: "white", backgroundColor: "#26C6F5" }}
                />
              </Grid>
              <Grid item xs={9}>
                {admin && (
                  <StudentSeverityIndicator
                    report={report}
                    student={s}
                    setSelectedStudents={setSelectedStudents}
                    selectedStudents={selectedStudents}
                  />
                )}
              </Grid>
            </React.Fragment>
          ))}
      </Grid>
      {showEdit && studentsObj && (
        <Autocomplete
          multiple
          sx={{ mt: 2, mb: 2 }}
          options={studentsForSelect}
          getOptionLabel={(student) =>
            student.firstName + " " + student.lastName + " - " + studentsObj[student.sid].homeroom
          }
          onChange={handleChange}
          value={selectedStudents}
          filterSelectedOptions
          isOptionEqualToValue={(option: StudentsForSelect, value: StudentsForSelect) =>
            option.sid === value.sid
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Selected Students"
              placeholder="Select Selected Students"
            />
          )}
        />
      )}
    </>
  );
}

export default StudentsInvolved;
