import { ChangeEvent, SyntheticEvent } from "react";
import { TextField, Typography, Button, Divider } from "@mui/material";
import CaregiversContacted from "./CaregiversContacted";
import HowCanSELHelp from "./HowCanSELHelp";
import SelectStudents from "./SelectStudents";
import ImageUpload from "./ImageUpload";
import WhenIncidentOccured from "./WhenIncidentOccured";
import SelectIncidentType from "./SelectIncidentType";
import { ReportInterface } from "../../interfaces/interfaces";
import { ImageListType } from "react-images-uploading";
import PeriodOfIncident from "./PeriodOfIncident";

type Props = {
  submit: (event: SyntheticEvent) => void;
  post: ReportInterface;
  setPost: (pV: ReportInterface) => void;
  images: ImageListType;
  setImages: (pV: ImageListType) => void;
};

function Form({ submit, post, setPost, images, setImages }: Props) {
  const manageDescriptionLimit = (event: ChangeEvent<HTMLInputElement>) => {
    setPost({ ...post, reportDescription: event.target.value.substring(0, 1000) });
  };
  return (
    <form onSubmit={submit}>
      <SelectIncidentType post={post} />
      <Divider sx={{ mt: 1 }} />
      <Typography sx={{ mt: 2 }}>When did this incient take place?</Typography>
      <WhenIncidentOccured post={post} setPost={setPost} />
      <Typography sx={{ mt: 2 }}>
        Describe your specific OBSERVATIONS (Only facts, not interpretation or assumptions. What
        would a camera see). Please specify what happened right before the incident, what the
        incident was, and how the incident resolved.
      </Typography>
      <Typography
        variant="caption"
        sx={{ mt: 2, color: post.reportDescription.length > 950 ? "#F50901" : "#000000" }}
      >
        {`${post.reportDescription.length} out of 1000`} characters
      </Typography>
      <TextField
        fullWidth
        required
        id="reportDescription"
        name="reportDescription"
        label="Report Description"
        value={post.reportDescription}
        multiline
        rows={4}
        sx={{ mt: 2 }}
        onChange={manageDescriptionLimit}
      />
      <Typography sx={{ mt: 2 }}>Select All Students Involved</Typography>
      <SelectStudents post={post} setPost={setPost} />
      <Typography sx={{ mt: 2 }}>
        Were there other witnesses including students, staff or visitors or therapists? If so, name
        and organization?
      </Typography>
      <TextField
        fullWidth
        sx={{ mt: 2 }}
        id="witnesses"
        name="witnesses"
        label="Witnesses?"
        onChange={(e) => setPost({ ...post, witnesses: e.target.value })}
      />
      <Typography sx={{ mt: 2 }}>
        What part of the day did the incident take place? (required)*
      </Typography>
      <PeriodOfIncident post={post} setPost={setPost} />

      <CaregiversContacted post={post} setPost={setPost} />

      <Typography sx={{ mt: 2 }}>Optional Additional Information?</Typography>

      <TextField
        fullWidth
        sx={{ mt: 2 }}
        id="anythingElse"
        name="anythingElse"
        label="Anything Else?"
        multiline
        rows={4}
        onChange={(e) => setPost({ ...post, anythingElse: e.target.value })}
      />
      <ImageUpload images={images} setImages={setImages} />

      <Button variant="contained" fullWidth type="submit" sx={{ mt: 4, mb: 2 }}>
        Submit
      </Button>
    </form>
  );
}

export default Form;
