import { db } from "../firebase";
import { Button, Box, Typography } from "@mui/material";
import { doc, getDocs, collection, updateDoc } from "firebase/firestore";
import { COLLECTION } from "../libraries/objects";

const SELECTEC_COLLECTION = COLLECTION.staff;

const SetRemoveToInactive = () => {
  const updateRemove = async () => {
    const ref = collection(db, SELECTEC_COLLECTION);
    const snapshot = await getDocs(ref);
    snapshot.forEach((row) => {
      const docRef = doc(db, SELECTEC_COLLECTION, row.id);
      if (row.data().permission === "remove") {
        updateDoc(docRef, { active: false });
      }
    });
  };

  return (
    <>
      <Box sx={{ margin: 4 }}>
        <Typography variant="h5">Set Remove to Inactive</Typography>
        <Button sx={{ mr: 2 }} onClick={updateRemove} variant="contained">
          Convert Remove to Inactive
        </Button>
      </Box>
    </>
  );
};

export default SetRemoveToInactive;
