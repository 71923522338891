import { ThemeProvider } from "@mui/material/styles";
import { RecoilRoot } from "recoil";
import { theme } from "./Theme";
import AppBootstrap from "./AppBootstrap";
import AuthProvider from "./providers/AuthProvider";

function App() {
  return (
    <RecoilRoot>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <AppBootstrap />
        </ThemeProvider>
      </AuthProvider>
    </RecoilRoot>
  );
}

export default App;
