import { useRef } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useRecoilValue } from "recoil";
import { useReactToPrint } from "react-to-print";
import PrintTable from "./PrintTable";
import { reportsToShowAtom } from "../recoil/atoms";

function PrintBar() {
  const componentRef = useRef(null);
  const reportsToShow = useRecoilValue(reportsToShowAtom);

  const loading = Boolean(!reportsToShow);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <>
      <Box
        sx={{
          mb: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          pt: 3,
        }}
      >
        <Box>
          <Typography variant="h2">Print Filtered Reports</Typography>
        </Box>
        <Button onClick={handlePrint} variant="contained">
          Print
        </Button>
      </Box>
      {!loading && (
        <Box sx={{ display: "none" }}>
          <Box
            ref={componentRef}
            sx={{ width: "8.5in", margin: "0px auto", backgroundColor: "#ffffff", padding: 4 }}
          >
            <PrintTable reportsToShow={reportsToShow} />
          </Box>
        </Box>
      )}
    </>
  );
}

export default PrintBar;
