import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginContainer from "./components/Login/LoginContainer";
import StaffPage from "./pages/StaffPage";
import CustomRoute from "./CustomRoute";
import Form from "./pages/Form";
import AddActiveToCollection from "./dbchanges/AddActiveToCollection";
import UserReports from "./pages/UserReports";
import AllReports from "./pages/AllReports";
import SetRemoveToInactive from "./dbchanges/SetRemoveToInactive";
import ConvertTimestampToCreatedAt from "./dbchanges/ConvertTimestampToCreatedAt";
import Rollups from "./pages/Rollups";
import DBChanges from "./dbchanges/DBChanges";

export const routes = (
  <Routes>
    <Route path="/login" element={<LoginContainer />} />
    <Route
      path="/staff"
      element={
        <CustomRoute admin={true}>
          <StaffPage />
        </CustomRoute>
      }
    />
    <Route
      path="/user-reports"
      element={
        <CustomRoute admin={false}>
          <UserReports />
        </CustomRoute>
      }
    />
    <Route
      path="/all-reports"
      element={
        <CustomRoute admin={true}>
          <AllReports />
        </CustomRoute>
      }
    />
    <Route
      path="/form"
      element={
        <CustomRoute admin={false}>
          <Form />
        </CustomRoute>
      }
    />
    <Route
      path="/rollups"
      element={
        <CustomRoute admin={true}>
          <Rollups />
        </CustomRoute>
      }
    />
    <Route
      path="/db-changes"
      element={
        <CustomRoute admin={true}>
          <DBChanges />
        </CustomRoute>
      }
    />
    <Route
      path="/"
      element={
        <CustomRoute admin={false}>
          <Form />
        </CustomRoute>
      }
    />
  </Routes>
);
