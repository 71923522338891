import { useEffect } from "react";
import { Container } from "@mui/material";
import NavBar from "../components/Navigation/Navbar";
import AdminReportsContainer from "../components/Reports/AdminReportsContainer";
import { BLANK_FILTER_OBJ } from "../libraries/objects";
import { filtersAtom, totalReportsToShowAtom } from "../recoil/atoms";
import { useSetRecoilState } from "recoil";

function AllReports() {
  const setFilters = useSetRecoilState(filtersAtom);
  const setTotalReportsToShow = useSetRecoilState(totalReportsToShowAtom);

  useEffect(() => {
    setTotalReportsToShow(10);
    setFilters(BLANK_FILTER_OBJ);
  }, [setFilters, setTotalReportsToShow]);

  return (
    <>
      <NavBar></NavBar>
      <Container maxWidth="lg" sx={{ minHeight: "100vh", mt: 8, pt: 2 }}>
        <AdminReportsContainer />
      </Container>
    </>
  );
}

export default AllReports;
