import { SyntheticEvent, useState } from "react";
import { Radio, RadioGroup, FormControlLabel, FormControl, Typography } from "@mui/material";
import { ReportInterface } from "../../interfaces/interfaces";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import { STUDENT_SEVERITY_LEVELS } from "../../libraries/objects";
import { useSetRecoilState } from "recoil";
import { allReportsResetAtom } from "../../recoil/atoms";

type StudentsForSelect = {
  sid: string;
  firstName: string;
  lastName: string;
  severity: string;
};

type SetterFunction = (pV: StudentsForSelect) => StudentsForSelect;

type Props = {
  report: ReportInterface;
  student: StudentsForSelect;
  selectedStudents: StudentsForSelect[];
  setSelectedStudents: (pV: StudentsForSelect[]) => void;
};

type FormState = EventTarget & {
  name: string;
  value: string;
};

function StudentSeverityIndicator({
  report,
  student,
  selectedStudents,
  setSelectedStudents,
}: Props) {
  const { sendRequest: updateDoc } = useUpdateDoc();
  const [severity, setSeverity] = useState(student?.severity ?? "notCoded");
  const severityLevels: string[] = Object.keys(STUDENT_SEVERITY_LEVELS);

  const handleChange = async (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    const tempSelectedStudents: StudentsForSelect[] = [];
    selectedStudents.forEach((s) => {
      const tempObj = { ...s };
      if (s.sid === student.sid) {
        tempObj["severity"] = formState.value;
      }
      tempSelectedStudents.push(tempObj);
    });
    updateDoc({ col: "reports", id: report.id, data: { students: tempSelectedStudents } });
    setSelectedStudents(tempSelectedStudents);
    setSeverity(formState.value);
  };
  return (
    <FormControl sx={{ mb: 1 }}>
      <RadioGroup row name="severity-indicator" value={severity} onChange={handleChange}>
        {severityLevels.map((severityLevel) => (
          <FormControlLabel
            value={severityLevel}
            key={severityLevel}
            control={<Radio size="small" />}
            label={
              <Typography sx={{ fontSize: 12 }}>
                {STUDENT_SEVERITY_LEVELS[severityLevel]}
              </Typography>
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default StudentSeverityIndicator;
