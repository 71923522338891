import { useContext, useState, useEffect } from "react";
import { Badge, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { AuthContext } from "../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";

type ButtonState = EventTarget & {
  id: string;
};

function Weblinks() {
  const { currentUserData } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleRoute = (event: React.SyntheticEvent) => {
    const buttonState = event.target as ButtonState;
    navigate(`/${buttonState.id}`);
  };

  return (
    <>
      {currentUserData && currentUserData.permission === "admin" && (
        <>
          <Link className="webLink" to="/staff">
            STAFF
          </Link>
          <Link className="webLink" to="/all-reports">
            ALL REPORTS
          </Link>
          <Link className="webLink" to="/rollups">
            ROLLUPS
          </Link>
        </>
      )}
      <Typography
        variant="body2"
        id="form"
        onClick={handleRoute}
        sx={{ color: "#ffffff", mr: 2, ml: 1, cursor: "pointer" }}
      >
        SUBMIT INCIDENT
      </Typography>
      <Badge color="secondary" variant="dot" invisible>
        <Typography
          variant="body2"
          id="user-reports"
          onClick={handleRoute}
          sx={{ color: "#ffffff", cursor: "pointer" }}
        >
          MY REPORTS
        </Typography>
      </Badge>
    </>
  );
}

export default Weblinks;
