import { Button } from "@mui/material";
import ImageUploading, { ImageListType } from "react-images-uploading";

type Props = {
  images: ImageListType;
  setImages: (pV: ImageListType) => void;
};

export default function ImageUpload({ images, setImages }: Props) {
  const onChange = (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
    setImages(imageList);
  };

  return (
    <div className="App">
      <ImageUploading
        multiple
        value={images}
        acceptType={["jpg", "gif", "png", "jpeg"]}
        onChange={onChange}
        maxNumber={69}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          <div className="upload__image-wrapper">
            <Button
              onClick={onImageUpload}
              variant="contained"
              sx={{ marginTop: 2, marginBottom: 2 }}
              color="primary"
            >
              Click Here to Upload Image
            </Button>
            &nbsp;
            <Button
              variant="contained"
              sx={{ marginTop: 2, marginBottom: 2, backgroundColor: "#F50A00" }}
              onClick={onImageRemoveAll}
            >
              Remove all Images
            </Button>
            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img src={image["data_url"]} alt="" width="200" />
                <div className="image-item__btn-wrapper">
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      margin: 0.5,
                    }}
                    onClick={() => onImageUpdate(index)}
                  >
                    Update
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      margin: 0.5,
                      backgroundColor: "#F50A00",
                    }}
                    onClick={() => onImageRemove(index)}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
    </div>
  );
}
