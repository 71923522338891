import React, { createContext, useState, useEffect, useCallback } from "react";
import { signInWithPopup, UserCredential, User as FirebaseUser } from "firebase/auth";
import { auth, provider } from "../firebase";
import useGetDoc from "../hooks/useGetDoc";
import useSetDoc from "../hooks/useSetDoc";
import { StaffInterface } from "../interfaces/interfaces";

interface AuthContextInterface {
  loading: boolean;
  currentAuthUser: FirebaseUser | null;
  logout: () => void;
  signInWithGoogle: () => void;
  currentUserData: StaffInterface | null | undefined;
}

const initialState = () => ({
  currentAuthUser: null,
  logout: () => {},
  loading: false,
  signInWithGoogle: () => {},
  currentUserData: null,
});

const AuthContext = createContext<AuthContextInterface>(initialState());
export { AuthContext };

type Props = {
  children: JSX.Element;
};

const AuthProvider = ({ children }: Props) => {
  const [loading, setLoading] = useState(true);
  const [currentAuthUser, setCurrentAuthUser] = useState<FirebaseUser | null>(null);
  const [currentUserData, setCurrentUserData] = useState<StaffInterface | null>();
  const { sendRequest: getDoc } = useGetDoc();
  const { sendRequest: setDoc } = useSetDoc();

  const signInWithGoogle = useCallback(() => {
    signInWithPopup(auth, provider).then(async ({ user }: UserCredential) => {
      setLoading(true);
      if (!user || !user.email) {
        auth.signOut();
        setLoading(false);
        return;
      }
      user.email.split("@")[1] === "thegatheringplacek12.org" || auth.signOut();
      const userCheck = await getDoc({ col: "staff", id: user.uid });
      if (!userCheck) {
        setDoc({
          col: "staff",
          data: {
            displayName: user.displayName,
            email: user.email,
            uid: user.uid,
            photoURL: user.photoURL,
            permission: "staff",
          },
          id: user.uid,
        });
      }
      setLoading(false);
    });
  }, [getDoc, setDoc]);

  const logout = useCallback(() => {
    setCurrentUserData(null);
    return auth.signOut();
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user: FirebaseUser | null) => {
      setLoading(true);
      if (user) {
        setCurrentAuthUser(user);
        const userData = await getDoc<StaffInterface>({ col: "staff", id: user.uid });
        if (userData) {
          setCurrentUserData({
            id: userData?.id ?? "",
            displayName: userData?.displayName ?? "",
            photoURL: userData?.photoURL ?? "",
            email: userData?.email ?? "",
            permission: userData?.permission ?? "",
            uid: userData?.uid ?? "",
          });
        }
      } else {
        setCurrentAuthUser(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, [getDoc]);

  useEffect(() => {
    if (currentUserData) {
      setLoading(false);
    }
  }, [currentUserData]);

  const value = {
    currentAuthUser,
    signInWithGoogle,
    logout,
    loading,
    currentUserData,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
