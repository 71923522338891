import { Container } from "@mui/material";
import NavBar from "../components/Navigation/Navbar";
import UserReportsContainer from "../components/Reports/UserReportsContainer";

function UserReports() {
  return (
    <>
      <NavBar></NavBar>
      <Container maxWidth="md" sx={{ minHeight: "100vh", mt: 8, pt: 2 }}>
        <UserReportsContainer />
      </Container>
    </>
  );
}

export default UserReports;
