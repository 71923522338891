import {useState, useEffect} from 'react'
import { allReportsAtom } from '../../recoil/atoms'
import { useRecoilValue } from 'recoil'


function AllLogsContainer() {
    const [rows, setRows] = useState<any>();
    const allReports = useRecoilValue(allReportsAtom)
    
    useEffect(()=>{
        if(!allReports) return;
        const tempArray: any = [];
        allReports.forEach((report)=>{
            if(!report.studentIDs) return;
            let date: any = null;
            if(report.whenIncidentOccured){
                const toMillis = new Date(report.whenIncidentOccured.toMillis());
                date = toMillis.toLocaleDateString();
            }else if(report.timestamp){
                const toMillis = new Date(report.timestamp.toMillis());
                date = toMillis.toLocaleDateString();
            }
            report.studentIDs.forEach((studentId)=>{
                tempArray.push({studentId: studentId, date: date})
            })
        })
        setRows(tempArray);
    },[allReports])
  return (
    <>
        <table>
            {rows && rows.map((row: any)=>(
                <tr>
                    <td>
                        {row.studentId}
                    </td>
                    <td>
                        {row.date}
                    </td>
                </tr>
            ))}
        </table>
    </>
  )
}

export default AllLogsContainer