import { useState, useContext, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Checkbox,
  Chip,
  Box,
} from "@mui/material";
import { Avatar, Typography, IconButton, TextField, Badge } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { Comment, ReportInterface } from "../../interfaces/interfaces";
import DeleteCommentDialog from "./DeleteCommentConfirmation";
import { AuthContext } from "../../providers/AuthProvider";
import { getDatabase, ref, set } from "firebase/database";
import { staffObjAtom } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";

type Props = {
  comment: Comment;
  index: number;
  comments: Comment[];
  report: ReportInterface;
};

function CommentCard({ comment, index, comments, report }: Props) {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editToggle, setEditToggle] = useState(false);
  const [read, setRead] = useState(false);
  const [text, setText] = useState(comment.text);
  const dateString = new Date(comment.createdAt);
  const formatedDate = `${dateString.toLocaleDateString()} ${dateString.toLocaleTimeString()}`;
  const { currentUserData } = useContext(AuthContext);
  const staffObj = useRecoilValue(staffObjAtom);

  useEffect(() => {
    if (!currentUserData) return;
    setRead(comment.readEmails.includes(currentUserData.email));
  }, [currentUserData, comment]);

  const handleDelete = () => {
    setDeleteOpen(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const handleEditClick = () => {
    setEditToggle((pV) => !pV);
  };

  function writeUserData(data: Comment[]) {
    if (!currentUserData) return;
    const db = getDatabase();
    set(ref(db, "comments/" + report.id), data);
  }

  const handleSave = async () => {
    const currentDate = new Date();
    const editedComment = { ...comment };
    editedComment.text = text;
    editedComment.lastUpdated = currentDate.toISOString();
    const newComments = [...comments];
    newComments[index] = editedComment;
    setEditToggle((pV) => !pV);
    writeUserData(newComments);
  };

  const handleRead = async () => {
    if (!currentUserData) return;
    const currentDate = new Date();
    const editedComment = { ...comment };
    const indexOfCurrentUser = editedComment.readEmails.indexOf(currentUserData.email);
    if (indexOfCurrentUser === -1) {
      editedComment.readEmails.push(currentUserData.email);
      setRead(true);
    } else {
      editedComment.readEmails.splice(indexOfCurrentUser, 1);
      setRead(false);
    }
    editedComment.lastUpdated = currentDate.toISOString();
    const newComments = [...comments];
    newComments[index] = editedComment;
    writeUserData(newComments);
  };
  return (
    <>
      <Card sx={{ mt: 1, mb: 1 }}>
        <CardHeader
          avatar={<Avatar src={comment.avatarURL} />}
          title={<Typography variant="h6">{comment.authorName}</Typography>}
          subheader={formatedDate}
          action={
            <>
              {currentUserData && currentUserData.email !== comment.authorEmail && (
                <Badge variant="dot" color="secondary" invisible={read}>
                  <FormControlLabel
                    control={<Checkbox checked={read} />}
                    label={read ? "Read" : "Unread"}
                    onChange={handleRead}
                  />
                </Badge>
              )}
              {currentUserData && currentUserData.email === comment.authorEmail && (
                <>
                  {!editToggle ? (
                    <IconButton aria-label="settings" onClick={handleEditClick}>
                      <EditIcon />
                    </IconButton>
                  ) : (
                    <>
                      <IconButton aria-label="settings" onClick={handleSave}>
                        <SaveIcon />
                      </IconButton>
                      <IconButton aria-label="settings" onClick={handleEditClick}>
                        <CloseIcon />
                      </IconButton>
                    </>
                  )}
                  <IconButton aria-label="settings" onClick={handleDelete}>
                    <DeleteIcon />
                  </IconButton>
                </>
              )}
            </>
          }
        ></CardHeader>
        <CardContent>
          {!editToggle ? (
            <Typography>{comment.text}</Typography>
          ) : (
            <TextField multiline rows={3} fullWidth value={text} onChange={handleChange} />
          )}
          {staffObj && comment.taggedEmails && (
            <Box sx={{ mt: 2 }}>
              <Typography component="span" sx={{ fontWeight: 800 }}>
                Tagged:{" "}
              </Typography>
              {comment.taggedEmails.map((email) => (
                <Chip label={staffObj[email]?.displayName ?? ""} key={email} sx={{ mr: 1 }} />
              ))}
            </Box>
          )}
        </CardContent>
      </Card>
      <DeleteCommentDialog
        open={deleteOpen}
        setOpen={setDeleteOpen}
        comments={comments}
        commentIndex={index}
        report={report}
      />
    </>
  );
}

export default CommentCard;
