import { useEffect, useContext } from "react";
import useGetDocs from "./useGetDocs";
import { useSetRecoilState, useRecoilValue } from "recoil";
import {
  staffAtom,
  incidentTypesAtom,
  userReportsAtom,
  allReportsAtom,
  allReportsResetAtom,
  allReportsLoadingAtom,
  userReportsResetAtom,
  staffResetAtom,
  daysPerWeekAtom,
  daysPerWeekResetAtom,
} from "../recoil/atoms";
import {
  parseStaffResponse,
  parseIncidentTypeResponse,
  parseReportsResponse,
  parseDaysPerWeekResponse,
} from "../libraries/parsers";
import { AuthContext } from "../providers/AuthProvider";
import {
  IncidentTypeInterface,
  StaffInterface,
  ReportInterface,
  DaysPerWeekInterface,
} from "../interfaces/interfaces";

const useBootstrapEffect = () => {
  const { currentUserData } = useContext(AuthContext);
  const { sendRequest: getDocs } = useGetDocs();
  const setStaff = useSetRecoilState<StaffInterface[]>(staffAtom);
  const setIncidentTypes = useSetRecoilState<IncidentTypeInterface[]>(incidentTypesAtom);
  const setUserReports = useSetRecoilState<ReportInterface[] | null>(userReportsAtom);
  const setAllReports = useSetRecoilState(allReportsAtom);
  const setDaysPerWeek = useSetRecoilState<DaysPerWeekInterface[]>(daysPerWeekAtom);
  const setAllReportsLoading = useSetRecoilState(allReportsLoadingAtom);
  const userReportsReset = useRecoilValue(userReportsResetAtom);
  const allReportsReset = useRecoilValue(allReportsResetAtom);
  const staffReset = useRecoilValue(staffResetAtom);
  const daysPerWeekReset = useRecoilValue(daysPerWeekResetAtom);

  useEffect(() => {
    const getAllReports = async () => {
      setAllReportsLoading(true);
      const limitedReports = await getDocs<ReportInterface>({
        col: "reports",
        config: { orderBy: ["createdAt", "desc"], limit: 10 },
      });
      setAllReports(limitedReports);
      setAllReportsLoading(false);
      const filterDate = new Date("2023-07-01");

      const allReports = await getDocs<ReportInterface>({
        col: "reports",
        config: { where: ["createdAt", ">=", filterDate], orderBy: ["createdAt", "desc"] },
      });
      setAllReports(allReports);
    };
    getAllReports();
  }, [getDocs, setAllReports, setAllReportsLoading, allReportsReset]);

  useEffect(() => {
    const getStaff = async () => {
      const response = await getDocs<StaffInterface>({ col: "staff" });
      if (response) {
        setStaff(parseStaffResponse(response));
      }
    };
    getStaff();
  }, [setStaff, getDocs, staffReset]);

  useEffect(() => {
    const getIncidentTypes = async () => {
      const response = await getDocs<IncidentTypeInterface>({ col: "incidentTypes" });
      if (response) {
        setIncidentTypes(parseIncidentTypeResponse(response));
      }
    };
    getIncidentTypes();
  }, [setIncidentTypes, getDocs]);

  useEffect(() => {
    const getUserReports = async () => {
      if (!currentUserData) return;
      const response = await getDocs<ReportInterface>({
        col: "reports",
        config: { where: ["uid", "==", currentUserData.uid], orderBy: ["createdAt", "desc"] },
      });
      if (response) {
        setUserReports(parseReportsResponse(response));
      }
    };
    getUserReports();
  }, [setUserReports, getDocs, currentUserData, userReportsReset]);

  useEffect(() => {
    const getDaysPerWeek = async () => {
      const response = await getDocs<DaysPerWeekInterface>({
        col: "daysPerWeek",
      });
      if (response) {
        setDaysPerWeek(parseDaysPerWeekResponse(response));
      }
    };
    getDaysPerWeek();
  }, [getDocs, setDaysPerWeek, daysPerWeekReset]);
};

export default useBootstrapEffect;
