import { useEffect, useState } from "react";
import { GridToolbar, DataGridPro, GridPinnedRowsProp } from "@mui/x-data-grid-pro";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { reportsPerLocationAtom } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";
import { perHomeroomColumns } from "../../libraries/columns";
import useAnnualColumns from "../../hooks/useAnnualColumns";
import { SCHOOL_YEARS } from "../../libraries/objects";
import { ColumnInterface } from "../../interfaces/interfaces";

export default function PerLocationTable() {
  const [columns, setColumns] = useState<ColumnInterface[] | null>(null);
  const [firstRow, setFirstRow] = useState<GridPinnedRowsProp | null>(null);
  const reportsPerLocation = useRecoilValue(reportsPerLocationAtom);
  const { generateColumns } = useAnnualColumns();
  const window = useWindowDimensions();

  useEffect(() => {
    if (!reportsPerLocation) return;
    const dateColumns = generateColumns({ schoolYear: SCHOOL_YEARS.SY23_24 });
    const tempArray: ColumnInterface[] = [...perHomeroomColumns];
    dateColumns.forEach((column) => {
      const rows = reportsPerLocation.filter(
        (row: { [key: string]: any }) => row[column.field] > 0
      );
      if (rows.length > 0) {
        tempArray.push(column);
      }
    });
    setColumns(tempArray);
  }, [reportsPerLocation, generateColumns]);

  useEffect(() => {
    if (!reportsPerLocation) return;
    const pinnedRows: GridPinnedRowsProp = {
      top: [reportsPerLocation[0]],
    };
    setFirstRow(pinnedRows);
  }, [reportsPerLocation]);

  return (
    <div>
      {firstRow && reportsPerLocation && columns ? (
        <div style={{ height: window.height - 300, width: "100%" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGridPro
                rows={reportsPerLocation}
                columns={columns}
                rowHeight={34}
                columnBuffer={2}
                pinnedRows={firstRow}
                experimentalFeatures={{ rowPinning: true }}
                components={{
                  Toolbar: GridToolbar,
                }}
                initialState={{
                  pinnedColumns: { left: ["name"] },
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <Box sx={{ display: "block", textAlign: "center" }}>
          <CircularProgress size={80} />
        </Box>
      )}
    </div>
  );
}
