import { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  MenuItem,
  Select,
  SelectChangeEvent,
  CardHeader,
} from "@mui/material";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import { allReportsAtom, staffResetAtom } from "../../recoil/atoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { StaffInterface } from "../../interfaces/interfaces";

type Props = {
  staffMember: StaffInterface;
};

export default function StaffCard({ staffMember }: Props) {
  const [selectValue, setSelectValue] = useState(staffMember.permission);
  const setStaffReset = useSetRecoilState(staffResetAtom);
  const { sendRequest: updateDoc } = useUpdateDoc();
  const reports = useRecoilValue(allReportsAtom);

  const userReports = reports.filter((report) => report.uid === staffMember.uid);

  const handleSelectChange = async (event: SelectChangeEvent) => {
    let active = true;
    if (event.target.value === "remove") {
      active = false;
    }
    await updateDoc({
      col: "staff",
      id: staffMember.id,
      data: { permission: event.target.value, active },
    });
    setSelectValue(event.target.value);
    setStaffReset((pV) => !pV);
  };

  return (
    <Card>
      <CardHeader
        avatar={
          <img
            src={staffMember.photoURL}
            referrerPolicy="no-referrer"
            alt="profile"
            style={{ borderRadius: "50%", width: 64 }}
          />
        }
        title={<Typography variant="h6">{staffMember.displayName}</Typography>}
      />
      <CardContent>
        <Typography variant="body1">{`Total Submitted Reports: ${userReports.length}`}</Typography>
        <Select id="edit-user-settings" fullWidth value={selectValue} onChange={handleSelectChange}>
          <MenuItem value="staff">Staff</MenuItem>
          <MenuItem value="admin">Admin</MenuItem>
          <MenuItem value="remove">Remove</MenuItem>
        </Select>
      </CardContent>
    </Card>
  );
}
