import { useState, useEffect } from "react";
import { Grid, Box, CircularProgress } from "@mui/material";
import { userReportsAtom } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";
import SideMenu from "./SideMenu";
import { ROLLUP_VIEWS } from "../../libraries/objects";
import FiltersAccordion from "./FiltersAccordion";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import Rollups from "./Rollups";
import MobileAccordionMenu from "./MobileAccordionMenu";

const RollupsContainer = () => {
  const reports = useRecoilValue(userReportsAtom);
  const [activeRollupView, setActiveRollupView] = useState(ROLLUP_VIEWS.PER_CHILD);
  const [collapse, setCollapse] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width > 1000) {
      setCollapse(false);
    }

    if (width <= 1000) {
      setCollapse(true);
    }
  }, [width]);

  return (
    <>
      {!reports ? (
        <Box sx={{ mt: 8, textAlign: "center", pt: 8 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {!collapse && (
            <Grid container spacing={0}>
              <Grid item xs={2}>
                <SideMenu setActiveRollupView={setActiveRollupView} />
              </Grid>
              <Grid item xs={10}>
                <Box sx={{ padding: 2 }}>
                  <FiltersAccordion />
                </Box>
                <Rollups activeRollupView={activeRollupView} />
              </Grid>
            </Grid>
          )}
          {collapse && (
            <>
              <Box sx={{ padding: 2 }}>
                <MobileAccordionMenu setActiveRollupView={setActiveRollupView} />
              </Box>
              <Box sx={{ padding: 2 }}>
                <FiltersAccordion />
              </Box>
              <Rollups activeRollupView={activeRollupView} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default RollupsContainer;
