import { useState, SyntheticEvent } from "react";
import { TextField } from "@mui/material";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { daysPerWeekAtom, daysPerWeekResetAtom } from "../../recoil/atoms";
import { ColumnInterface } from "../../interfaces/interfaces";
import useSetDoc from "../../hooks/useSetDoc";

type Props = {
  week: ColumnInterface;
};

type FormState = EventTarget & {
  name: string;
  value: string;
};

function Day({ week }: Props) {
  const { sendRequest: setDoc } = useSetDoc();
  const daysPerWeek = useRecoilValue(daysPerWeekAtom);
  const setDaysPerWeekReset = useSetRecoilState(daysPerWeekResetAtom);
  const currentDaysPerWeek = daysPerWeek.find((dayPerWeek) => dayPerWeek.id === week.field);
  const [days, setDays] = useState(currentDaysPerWeek?.days ?? 0);

  const handleChange = async (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setDays(Number(formState.value));
    await setDoc({
      col: "daysPerWeek",
      id: formState.name,
      data: { days: Number(formState.value) },
    });
    setDaysPerWeekReset((pV) => !pV);
  };

  return (
    <TextField
      label={week.headerName}
      onChange={handleChange}
      name={week.field}
      value={days !==0 ? String(days) : ""}
      type="number"
      InputLabelProps={{ shrink: true }}
    />
  );
}

export default Day;
