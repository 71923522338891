import { useContext } from "react";
import { MenuItem, Badge } from "@mui/material";
import { Link } from "react-router-dom";
import { AuthContext } from "../../providers/AuthProvider";

type Props = {
  handleClose: () => void;
};

export default function MobileLinks({ handleClose }: Props) {
  const { currentUserData } = useContext(AuthContext);

  return (
    <>
      {currentUserData && currentUserData.permission === "admin" && (
        <>
          <MenuItem onClick={handleClose}>
            <Link to="/staff" className="navLinkMenu">
              Staff
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to="/all-reports" className="navLinkMenu">
              All Reports
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to="/rollups" className="navLinkMenu">
              Roll Ups
            </Link>
          </MenuItem>
        </>
      )}
      <MenuItem onClick={handleClose}>
        <Link to="/form" className="navLinkMenu">
          Submit Incident Report
        </Link>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <Badge invisible color="secondary" variant="dot">
          <Link to="/user-reports" className="navLinkMenu">
            My Reports
          </Link>
        </Badge>
      </MenuItem>
    </>
  );
}
