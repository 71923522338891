import { Typography, Container } from "@mui/material";
import PerVillageTable from "./PerVillageTable";

function PerVillageContainer() {
  return (
    <>
      <Container sx={{ backgroundColor: "#fff", pt: 2 }}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Average Reports Per Week By Village
        </Typography>
        <Typography sx={{ mt: 1, mb: 2 }}>
          This report takes the average number of incidents / day for a given week and multiples
          that by 5 to show the average number of reports per week if it had been a 5 day week. For
          the school weeks that have 5 days, this will be the actual number of incidents / day. For
          weeks with less than 5 days, the numbers reported will show how many reports their would
          have been if it had been a 5 day week. The purpose of this report it to compare weeks
          fairly even if some weeks had less days in them.
        </Typography>
        <PerVillageTable />
      </Container>
    </>
  );
}

export default PerVillageContainer;
