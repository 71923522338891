import { TextField, Autocomplete, Container } from "@mui/material";
import { studentsAtom } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";
import { FilterInterface } from "../../interfaces/interfaces";

type Props = {
  filters: FilterInterface;
  setFilters: (pV: FilterInterface) => void;
};

export default function SelectStudents({ filters, setFilters }: Props) {
  const students = useRecoilValue(studentsAtom);
  return (
    <Container sx={{ mt: 2 }}>
      {students && (
        <Autocomplete
          id="tags-outlined"
          options={students}
          fullWidth
          getOptionLabel={(student) =>
            student.childFirstName + " " + student.childLastName + " - " + student.homeroom
          }
          onChange={(_, value) => setFilters({ ...filters, selectedStudent: value })}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Children Involved"
              placeholder="Select Students"
            />
          )}
        />
      )}
    </Container>
  );
}
