import PerChildTable from "./PerChildTable";
import { Typography, Container } from "@mui/material";

export default function PerChildContainer() {
  return (
    <Container sx={{ backgroundColor: "#fff", pt: 2 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Reports Per Child
      </Typography>
      <PerChildTable />
    </Container>
  );
}
