import { useEffect } from "react";
import { Box } from "@mui/material";
import NavBar from "../components/Navigation/Navbar";
import RollupsContainer from "../components/Rollups/RollupsContainer";
import { BLANK_FILTER_OBJ } from "../libraries/objects";
import { filtersAtom, totalReportsToShowAtom } from "../recoil/atoms";
import { useSetRecoilState } from "recoil";

function Rollups() {
  const setFilters = useSetRecoilState(filtersAtom);
  const setTotalReportsToShow = useSetRecoilState(totalReportsToShowAtom);

  useEffect(() => {
    setTotalReportsToShow(10);
    setFilters(BLANK_FILTER_OBJ);
  }, [setFilters, setTotalReportsToShow]);
  return (
    <>
      <NavBar></NavBar>
      <Box sx={{ mt: 8, minHeight: "100VH" }}>
        <RollupsContainer />
      </Box>
    </>
  );
}

export default Rollups;
