import { TextField, Autocomplete, Container } from "@mui/material";
import { staffAtom } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";
import { FilterInterface } from "../../interfaces/interfaces";

type Props = {
  filters: FilterInterface;
  setFilters: (pV: FilterInterface) => void;
};

export default function SelectStaff({ filters, setFilters }: Props) {
  const staff = useRecoilValue(staffAtom);
  console.log(filters)
  return (
    <Container sx={{ mt: 2 }}>
      {staff && (
        <Autocomplete
          id="tags-outlined"
          options={staff}
          fullWidth
          getOptionLabel={(s) => `${s.displayName}`}
          onChange={(_, value) => setFilters({ ...filters, selectedStaff: value })}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Written By"
              placeholder="Select Staff"
            />
          )}
        />
      )}
    </Container>
  );
}
