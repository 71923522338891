import { useState, useEffect } from "react";
import {
  Chip,
  ImageList,
  Box,
  ImageListItem,
  Card,
  CardContent,
  Typography,
  CardActions,
} from "@mui/material";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

import CaregiversContacted from "./CaregiversContacted";
import { DateTime } from "luxon";
import { getGridListCols } from "../../libraries/functions";
import { ReportInterface } from "../../interfaces/interfaces";
import { PART_OF_DAY } from "../../libraries/objects";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import ReportHeader from "./ReportHeader";
import IncidentTypes from "./IncidentTypes";
import CommentsContainer from "../Comments/CommentsContainer";
import ReportFooter from "./ReportFooter";
import StudentsInvolved from "./StudentsInvolved";
import AdminNotesContainer from "./AdminNotesContainer";

type Props = {
  report: ReportInterface;
  admin: boolean;
  setReportToDelete?: (pV: ReportInterface | null) => void;
};

const Report = ({ report, admin, setReportToDelete }: Props) => {
  const [secondary, setSecondary] = useState(report.secondary ? true : false);
  let incidentDt = null;
  const { width } = useWindowDimensions();
  const [imageResults, setImageResults] = useState<string[]>([]);

  useEffect(() => {
    if (report.imageRef) {
      const imageUrls: string[] = [];
      const storage = getStorage();
      const getImages = async () => {
        await Promise.all(
          report.imageRef.map(async (file: string) => {
            try {
              const url = await getDownloadURL(ref(storage, file));
              imageUrls.push(url);
            } catch (error) {
              //console.log(error);
            }
          })
        );
        setImageResults(imageUrls);
      };
      getImages();
    }
  }, [report]);

  if (report.whenIncidentOccured) {
    incidentDt = DateTime.fromMillis(report.whenIncidentOccured.toMillis());
  }

  return (
    <>
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <ReportHeader report={report} admin={admin} />
          {secondary && (
            <Box sx={{ mt: 1 }}>
              <Chip label="Secondary" color="warning" />
            </Box>
          )}
          <Typography variant="h5" sx={{ marginTop: 2 }}>
            What Happened?
          </Typography>
          <Typography variant="body1" sx={{ marginLeft: 2 }}>
            {report.reportDescription}
          </Typography>
          <Typography variant="h5" sx={{ marginTop: 2 }}>
            Where did it Happen?
          </Typography>
          <Typography variant="body1" sx={{ marginLeft: 2 }}>
            {PART_OF_DAY[report.incidentLocation]}
          </Typography>
          {incidentDt && (
            <div>
              <Typography variant="h5" sx={{ marginTop: 2 }}>
                When did it Happen?
              </Typography>
              <Typography variant="body1" sx={{ marginLeft: 2 }}>
                {incidentDt.toLocaleString({
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}
              </Typography>
            </div>
          )}

          <Typography variant="h5" sx={{ marginTop: 2 }}>
            Were there other witnesses?
          </Typography>
          <Typography variant="body1" sx={{ marginLeft: 2 }}>
            {report.witnesses}
          </Typography>
          <CaregiversContacted report={report} />
          {report.incidentResolution && (
            <>
              <Typography variant="h5" sx={{ marginTop: 2 }}>
                How did the Incident Resolve?
              </Typography>

              <Typography variant="body1" sx={{ marginLeft: 2 }}>
                {report.incidentResolution}
              </Typography>
            </>
          )}
          {report.howSELCanHelp && (
            <>
              <Typography variant="h5" sx={{ marginTop: 2 }}>
                How can the school support?
              </Typography>
              <Typography variant="body1" sx={{ marginLeft: 2 }}>
                {report.howSELCanHelp}
              </Typography>
            </>
          )}
          {report.anythingElse && (
            <>
              <Typography variant="h5" sx={{ marginTop: 2 }}>
                Anything Else?
              </Typography>
              <Typography variant="body1" sx={{ marginLeft: 2 }}>
                {report.anythingElse}
              </Typography>
            </>
          )}
          <StudentsInvolved report={report} admin={admin} />
          <IncidentTypes report={report} />
          {imageResults && (
            <ImageList variant="quilted" cols={getGridListCols(width)} gap={8}>
              {imageResults.map((i) => (
                <ImageListItem key={i}>
                  <img
                    srcSet={`${i}&w=161&fit=crop&auto=format 1x,
          ${i}&w=161&fit=crop&auto=format&dpr=2 2x`}
                    alt="What Happened"
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          )}
          {admin && <AdminNotesContainer report={report} />}
          <Typography variant="h5">Comments:</Typography>
          <Typography variant="body2">The most recent comments are at the top:</Typography>
          <CommentsContainer report={report} />
        </CardContent>
        {admin && setReportToDelete && (
          <CardActions sx={{ display: "flex", justifyContent: "space-between" }}>
            <ReportFooter
              setSecondary={setSecondary}
              report={report}
              setReportToDelete={setReportToDelete}
              secondary={secondary}
            />
          </CardActions>
        )}
      </Card>
      <div className="pagebreak"></div>
    </>
  );
};

export default Report;
