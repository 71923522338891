import React from "react";
import { Container } from "@mui/material";
import NavBar from "../components/Navigation/Navbar";
import FormContainer from "../components/Form/FormContainer";

const Form = () => {
  return (
    <>
      <NavBar></NavBar>
      <Container maxWidth="md" sx={{ minHeight: "100vh", mt: 8, pt: 2 }}>
        <FormContainer />
      </Container>
    </>
  );
};

export default Form;
