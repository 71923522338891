import { useState } from "react";
import { Box, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditCaregiverContacted from "./EditCaregiversContacted";
import { CONTACT_OPTIONS } from "../../libraries/objects";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import { userReportsResetAtom, allReportsResetAtom } from "../../recoil/atoms";
import { useSetRecoilState } from "recoil";

import { ReportInterface } from "../../interfaces/interfaces";

type Props = {
  report: ReportInterface;
};

const CaregiversContacted = ({ report }: Props) => {
  const [showEditCaregiverContact, setShowEditCaregiverContact] = useState(false);
  const [editCaregiverContact, setEditCaregiverContact] = useState(report.caregiverContact);
  const [editCaregiverContactNotes, setEditCaregiverContactNotes] = useState(
    report.caregiverContactedNotes
  );
  const { sendRequest: updateDoc } = useUpdateDoc();
  const setUserReportsReset = useSetRecoilState(userReportsResetAtom);
  const setAllReportsReset = useSetRecoilState(allReportsResetAtom);

  const handleShowEdit = () => {
    setShowEditCaregiverContact(true);
  };

  const handleCloseEdit = () => {
    setShowEditCaregiverContact(false);
  };

  const handleSave = async () => {
    await updateDoc({
      col: "reports",
      id: report.id,
      data: {
        caregiverContact: editCaregiverContact,
        caregiverContactedNotes: editCaregiverContactNotes,
      },
    });
    setShowEditCaregiverContact(false);
    setUserReportsReset((pV) => !pV);
    setAllReportsReset((pV) => !pV);
  };

  const caregiverText: any = CONTACT_OPTIONS[report?.caregiverContact]
    ? CONTACT_OPTIONS[report?.caregiverContact]
    : "No Contact Method Listed";

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
        <Typography variant="h5">Have Caregivers Been Contacted?</Typography>
        {!showEditCaregiverContact && (
          <EditIcon
            onClick={handleShowEdit}
            sx={{ ml: 2, fontSize: 18, cursor: "pointer" }}
            color="primary"
          />
        )}
        {showEditCaregiverContact && (
          <>
            <CheckCircleIcon
              onClick={handleSave}
              sx={{ ml: 1, fontSize: 22, cursor: "pointer" }}
              color="primary"
            />
            <CloseIcon
              onClick={handleCloseEdit}
              sx={{ ml: 1, fontSize: 22, cursor: "pointer" }}
              color="error"
            />
          </>
        )}
      </Box>
      {!showEditCaregiverContact && (
        <Typography variant="body1" sx={{ marginLeft: 2 }}>
          {caregiverText}
        </Typography>
      )}
      {showEditCaregiverContact && (
        <EditCaregiverContacted
          setEditCaregiverContact={setEditCaregiverContact}
          setEditCaregiverContactNotes={setEditCaregiverContactNotes}
          editCaregiverContact={editCaregiverContact}
          editCaregiverContactNotes={editCaregiverContactNotes}
        />
      )}

      {!showEditCaregiverContact && report.caregiverContactedNotes && (
        <>
          <Typography variant="h5" sx={{ marginTop: 2 }}>
            Caregiver Contact Notes
          </Typography>
          <Typography variant="body1" sx={{ marginLeft: 2 }}>
            {report.caregiverContactedNotes}
          </Typography>
        </>
      )}
    </>
  );
};

export default CaregiversContacted;
