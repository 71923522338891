import { Box, Button, TextField, Typography } from "@mui/material";
import React, { ChangeEvent, HtmlHTMLAttributes, useState } from "react";
import { ReportInterface } from "../../interfaces/interfaces";
import EditIcon from "@mui/icons-material/Edit";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import { useSetRecoilState } from "recoil";
import { allReportsResetAtom } from "../../recoil/atoms";

type Props = {
  report: ReportInterface;
};

const AdminNotesContainer = ({ report }: Props) => {
  const { sendRequest: updateDoc } = useUpdateDoc();
  const setAllReportsReset = useSetRecoilState(allReportsResetAtom);
  const { adminNotes } = report;
  const [edit, setEdit] = React.useState<boolean>(false);
  const [note, setNote] = useState<string>(adminNotes);

  const handleSave = async () => {
    await updateDoc({ col: "reports", id: report.id, data: { adminNotes: note } });
    setEdit(false);
    setAllReportsReset((pV) => !pV);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value);
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h5">Admin Description:</Typography>
        {!edit && (
          <EditIcon
            onClick={() => setEdit(true)}
            sx={{ ml: 2, fontSize: 18, cursor: "pointer" }}
            color="primary"
          />
        )}
      </Box>

      {!edit ? (
        <Typography variant="body1" sx={{ mb: 1 }}>
          {adminNotes}
        </Typography>
      ) : (
        <>
          <TextField multiline fullWidth rows={4} value={note} onChange={handleChange} />
          <Button sx={{ mt: 1, mb: 1 }} variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
          <Button
            sx={{ mt: 1, mb: 1, ml: 1 }}
            variant="contained"
            color="error"
            onClick={() => setEdit(false)}
          >
            Cancel
          </Button>
        </>
      )}
    </>
  );
};

export default AdminNotesContainer;
