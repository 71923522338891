import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

type ChartData = { name: string; amt: number };

type Props = {
  chartData: ChartData[] | null;
};

export default function AveragePerDayChart({ chartData }: Props) {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (!chartData) return;
    setWidth(chartData.length * 150);
  }, [chartData]);

  return (
    <>
      {chartData && (
        <Box sx={{ overflow: "scroll" }}>
          <ResponsiveContainer width={width} height={600}>
            <BarChart
              data={chartData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="amt" fill="#B930E6" name="Average Incidents / Day" />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      )}
    </>
  );
}
