import { db } from "../firebase";
import { Button, Box, Typography } from "@mui/material";
import { doc, getDocs, collection, updateDoc } from "firebase/firestore";
import { COLLECTION } from "../libraries/objects";

const SELECTEC_COLLECTION = COLLECTION.reports;

const ConvertTimestampToCreatedAt = () => {
  const convertTimestamp = async () => {
    const ref = collection(db, SELECTEC_COLLECTION);
    const snapshot = await getDocs(ref);
    snapshot.forEach((row) => {
      const docRef = doc(db, SELECTEC_COLLECTION, row.id);
      if (row.data()) {
        if (!row.data().createdAt && row.data().timestamp) {
          updateDoc(docRef, { createdAt: row.data().timestamp });
        }
      }
    });
  };

  return (
    <>
      <Box sx={{ margin: 4 }}>
        <Typography variant="h5">Convert timestamp key to createdAt key</Typography>
        <Button sx={{ mr: 2 }} onClick={convertTimestamp} variant="contained">
          Convert Timestamps
        </Button>
      </Box>
    </>
  );
};

export default ConvertTimestampToCreatedAt;
