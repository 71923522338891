import { useEffect, useState } from "react";
import { GridToolbar, DataGridPro } from "@mui/x-data-grid-pro";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { reportsPerChildAtom } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";
import { perChildColumns } from "../../libraries/columns";
import useAnnualColumns from "../../hooks/useAnnualColumns";
import { SCHOOL_YEARS } from "../../libraries/objects";
import { ColumnInterface } from "../../interfaces/interfaces";

export default function PerChildTable() {
  const [columns, setColumns] = useState<ColumnInterface[] | null>(null);
  const reportsPerChild = useRecoilValue(reportsPerChildAtom);
  const { generateColumns } = useAnnualColumns();
  const window = useWindowDimensions();

  useEffect(() => {
    if (!reportsPerChild) return;
    const dateColumns = generateColumns({ schoolYear: SCHOOL_YEARS.SY23_24 });
    const tempArray: ColumnInterface[] = [...perChildColumns];
    dateColumns.forEach((column) => {
      const rows = reportsPerChild.filter((row: { [key: string]: any }) => row[column.field] > 0);
      if (rows.length > 0) {
        tempArray.push(column);
      }
    });
    setColumns(tempArray);
  }, [reportsPerChild, generateColumns]);

  return (
    <div>
      {reportsPerChild && columns ? (
        <div style={{ height: window.height - 300 }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGridPro
                rows={reportsPerChild}
                columns={columns}
                rowHeight={34}
                columnBuffer={2}
                components={{
                  Toolbar: GridToolbar,
                }}
                initialState={{
                  pinnedColumns: { left: ["firstName", "lastName"] },
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <Box sx={{ display: "block", textAlign: "center" }}>
          <CircularProgress size={80} />
        </Box>
      )}
    </div>
  );
}
