import { Box, Typography } from "@mui/material";
import { ColumnInterface } from "../../interfaces/interfaces";
import Day from "./Day";

type Props = {
  weeks: ColumnInterface[];
};

function DaysPerWeek({ weeks }: Props) {
  return (
    <>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h5">School Days / Week</Typography>
        <Box sx={{ height: "70vh", overflow: "scroll" }}>
          {weeks &&
            weeks.map((week) => (
              <Box sx={{ margin: 2 }} key={week.field}>
                <Day week={week} />
              </Box>
            ))}
        </Box>
      </Box>
    </>
  );
}

export default DaysPerWeek;
