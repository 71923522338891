import { FilterInterface, ReportInterface } from "../interfaces/interfaces";
import { Timestamp } from "firebase/firestore";

export enum COLLECTION {
  daysPerWeek = "daysPerWeek",
  incidentTypes = "incidentTypes",
  reports = "reports",
  staff = "staff",
}

export const PART_OF_DAY: { [key: string]: string } = {
  arrival: "Arrival",
  morningCircle: "Morning Cirlce",
  literacy: "RLA",
  numeracy: "Math",
  pbl: "Project Work Time",
  lunch: "Lunch",
  snack: "Snack",
  music: "PE",
  transition: "Transition",
  visualArt: "Visual Art",
  intervention: "Intervention",
  digitalNumeracy: "Digital Fluency",
  recess: "Recess",
  wheelHouse: "Wheelhouse",
  culinaryArts: "Culinary Arts",
  immersionArt: "Kinder Immersion Art",
  kinderLiteracy: "Kinder Literacy",
  dismissal: "Dismissal",
  clubs: "Clubs",
  other: "Other",
};

export const PART_OF_DAY_ARRAY = Object.keys(PART_OF_DAY);

export const COLLECTIONS = Object.values(COLLECTION);

export const BLANK_INCIDENT_FORM: ReportInterface = {
  id: "",
  author: "",
  caregiverContact: "noContact",
  caregiverContactedNotes: "",
  howSELCanHelp: "",
  witnesses: "",
  uid: "",
  timestamp: null,
  students: [],
  studentIDs: [],
  selectedITs: [],
  incidentResolution: "",
  incidentLocation: "",
  reportDescription: "",
  anythingElse: "",
  whenIncidentOccured: Timestamp.now(),
  imageRef: [],
  secondary: false,
  createdAt: null,
  severity: "notCoded",
  adminNotes: "",
};

export const BLANK_FILTER_OBJ: FilterInterface = {
  selectedStaff: null,
  selectedStudent: null,
  dateRange: [null, null],
  contacted: "all",
  selectedITs: [],
  selectedSeverity: [],
};

export const CONTACT_OPTIONS: any = {
  noContact:
    "I have not contacted caregivers about this incident and I have not verified someone will.",
  yesContact: "Yes, I have personally contacted caregivers about this incident",
  verifiedSomeoneElse:
    "Yes, I have personally verified someone else will contact caregiver about this incident.",
  verifiedNoCommsNeeded:
    "I have confirmed with a school leader or manager that I do not need to communicate about this incident.",
};

export const CONTACT_OPTIONS_DESCRIPTIONS: any = {
  yesContact:
    "Who did you contact and how did you contact them? Please share if spoke with them,communicated back and forth, or simply sent a message.",
  verifiedSomeoneElse:
    "Who did you verify would be communicating with the caregiver about this incident so we can follow-up with them.",
  verifiedNoCommsNeeded:
    "Which school leader or manager confirmed you do not need to communicate with a caregiver about this incident?",
};

export const CONTACT_OPTIONS_ARRAY = [
  { key: "noContact", value: CONTACT_OPTIONS.noContact },
  { key: "yesContact", value: CONTACT_OPTIONS.yesContact },
  { key: "verifiedSomeoneElse", value: CONTACT_OPTIONS.verifiedSomeoneElse },
  { key: "verifiedNoCommsNeeded", value: CONTACT_OPTIONS.verifiedNoCommsNeeded },
];

export const ROLLUP_VIEWS = {
  PER_CHILD: "Per Child",
  PER_TEACHER: "Per Teacher",
  PER_HOMEROOM: "Per Homeroom",
  AVERAGE_PER_DAY: "Average Per Day",
  ALL_LOGS: "All Logs",
  PER_VILLAGE: "Per Village",
  PER_LOCATION: "Per Location",
};

export const SCHOOL_YEARS = {
  SY21_22: "SY 21 - 22",
  SY22_23: "SY 22 - 23",
  SY23_24: "SY 23 - 24",
  SY24_25: "SY 24 - 25",
};

export const SEVERITY_LEVELS: { [key: string]: string } = {
  notCoded: "Not Coded",
  levelOne: "Level 1",
  levelTwo: "Level 2",
  levelThree: "Level 3",
  levelFour: "Level 4",
};

export const STUDENT_SEVERITY_LEVELS: { [key: string]: string } = {
  notCoded: "Uncoded",
  levelOne: "L1",
  levelTwo: "L2",
  levelThree: "L3",
  levelFour: "L4",
  injury: "Injury",
  mentalHealth: "Mental Health",
  na: "N/A",
};
