import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./providers/AuthProvider";

interface Props {
  children: React.ReactNode;
  admin: boolean;
}

const CustomRoute = ({ children, admin }: Props) => {
  const { currentUserData, loading } = useContext(AuthContext);

  return (
    <>
      {!currentUserData && !loading && <Navigate to="/login" />}
      {admin && currentUserData && currentUserData.permission === "admin" && !loading && children}
      {admin && currentUserData && currentUserData.permission !== "admin" && !loading && (
        <Navigate to="/form" />
      )}
      {!admin && currentUserData && !loading && children}
    </>
  );
};

export default CustomRoute;
