import { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { homeroomsAtom, studentsAtom, studentsSPEDObjectAtom, villagesAtom } from "../recoil/atoms";
import { HomeroomInterface, StudentInterface, VillagesInterface } from "../interfaces/interfaces";

export interface RawStudentInterface {
  id: string;
  childFirstName: string;
  childLastName: string;
  enrollStatus: string;
  homeroom: string;
  SID: string;
  SPED: string;
}

const useBootstrapAPIEffect = () => {
  const [loading, setLoading] = useState(false);
  const [rawStudents, setRawStudents] = useState<RawStudentInterface[] | null>();
  const [homerooms, setHomerooms] = useRecoilState<HomeroomInterface[]>(homeroomsAtom);
  const [students, setStudents] = useRecoilState<StudentInterface[]>(studentsAtom);
  const [villages, setVillages] = useRecoilState<VillagesInterface[]>(villagesAtom);
  const setStudentsSPEDObject = useSetRecoilState(studentsSPEDObjectAtom);

  useEffect(() => {
    setLoading(true);
    if (homerooms.length > 0 && students.length > 0) {
      setLoading(false);
    }
  }, [homerooms, students]);

  useEffect(() => {
    const getHomerooms = async () => {
      const headers: HeadersInit = {
        "Content-Type": "application/json",
        authorization: process.env.REACT_APP_FIREBASE_API_KEY ?? "",
      };
      const response = await fetch(
        "https://us-central1-tgp-core-api.cloudfunctions.net/app/api/homerooms",
        {
          headers: headers,
        }
      );
      const json = await response.json();
      setHomerooms(json);
    };
    getHomerooms();
  }, [setHomerooms]);

  useEffect(() => {
    const getStudents = async () => {
      if (homerooms.length < 1) return;
      const headers: HeadersInit = {
        "Content-Type": "application/json",
        authorization: process.env.REACT_APP_FIREBASE_API_KEY ?? "",
      };
      const response = await fetch(
        "https://us-central1-tgp-core-api.cloudfunctions.net/app/api/students",
        {
          headers: headers,
        }
      );
      const studentList = await response.json();
      setRawStudents(studentList);
    };
    getStudents();
  }, [setStudents, homerooms, setStudentsSPEDObject]);

  useEffect(() => {
    const getVillages = async () => {
      const headers: HeadersInit = {
        "Content-Type": "application/json",
        authorization: process.env.REACT_APP_FIREBASE_API_KEY ?? "",
      };
      const response = await fetch(
        "https://us-central1-tgp-core-api.cloudfunctions.net/app/api/villages",
        {
          headers: headers,
        }
      );
      const villagesList = await response.json();
      setVillages(villagesList);
    };
    getVillages();
  }, [setVillages]);

  useEffect(() => {
    if (!homerooms || !rawStudents) return;
    const studentArray: StudentInterface[] = [];
    const tempObj: { [key: string]: string } = {};
    rawStudents.forEach((student: RawStudentInterface) => {
      // if(true){
      if (student.enrollStatus === "Actively Enrolled") {
        const homeroom = homerooms.find((homeroom) => homeroom.id === student.homeroom);
        studentArray.push({
          ...student,
          homeroom: homeroom?.name ?? "",
          homeroomId: student.homeroom,
        });
        tempObj[student.SID] = student.SPED;
      }
    });
    setStudentsSPEDObject(tempObj);
    setStudents(studentArray);
  }, [rawStudents, setStudents, homerooms, setStudentsSPEDObject]);

  return loading;
};

export default useBootstrapAPIEffect;
