import { SyntheticEvent } from "react";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Container,
  Typography,
  TextField,
  SelectChangeEvent,
} from "@mui/material";
import { CONTACT_OPTIONS_ARRAY, CONTACT_OPTIONS_DESCRIPTIONS } from "../../libraries/objects";

type Props = {
  editCaregiverContactNotes: string;
  setEditCaregiverContactNotes: (pV: string) => void;
  editCaregiverContact: string;
  setEditCaregiverContact: (pV: string) => void;
};

type FormState = EventTarget & {
  name: string;
  value: string;
};

export default function EditCaregiversContacted({
  editCaregiverContactNotes,
  setEditCaregiverContactNotes,
  editCaregiverContact,
  setEditCaregiverContact,
}: Props) {
  const handleSelectChange = (event: SelectChangeEvent) => {
    setEditCaregiverContact(event.target.value);
  };

  const handleTextChange = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setEditCaregiverContactNotes(formState.value);
  };

  return (
    <Container sx={{ mt: 2 }}>
      <FormControl fullWidth>
        <InputLabel id="contact-type-label">Caregivers Contacted?</InputLabel>
        <Select
          labelId="contact-type-label"
          value={editCaregiverContact}
          label="Caregivers Contacted?"
          onChange={handleSelectChange}
        >
          {CONTACT_OPTIONS_ARRAY.map((option) => (
            <MenuItem value={option.key} key={option.key}>
              {option.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Typography sx={{ mt: 2 }}>{CONTACT_OPTIONS_DESCRIPTIONS[editCaregiverContact]}</Typography>
      <TextField
        fullWidth
        sx={{ mt: 2 }}
        label="Please Share Contact Details"
        multiline
        value={editCaregiverContactNotes}
        rows={4}
        onChange={handleTextChange}
      />
    </Container>
  );
}
