import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  allReportsLoadingAtom,
  reportsToShowAtom,
  totalReportsToShowAtom,
} from "../../recoil/atoms";
import { Box, CircularProgress, Container } from "@mui/material";
import Filter from "./Filter";
import Report from "./Report";
import DeleteDialog from "./DeleteDialog";
import { ReportInterface } from "../../interfaces/interfaces";
import PrintBar from "../../Print/PrintBar";

export default function AdminReportsContainer() {
  const loading = useRecoilValue(allReportsLoadingAtom);
  const reportsToShow = useRecoilValue(reportsToShowAtom);
  const setTotalReportsToShow = useSetRecoilState(totalReportsToShowAtom);
  const [reportToDelete, setReportToDelete] = useState<ReportInterface | null>(null);
  const [readyToLoadNewReports, setReadyToLoadNewReports] = useState(true);
  const [previousReportsToShowCount, setPreviousReportsToShowCount] = useState(0);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [fetchPosition, setFetchPosition] = useState(0);

  //the use effect blocks below prevent infinitely increasing the totalReportsToShow Atom once there are no reports left to load, which was causing performance issues when filters changed.
  useEffect(() => {
    if (!reportsToShow) return;
    if (previousReportsToShowCount !== reportsToShow.length) {
      setPreviousReportsToShowCount(reportsToShow.length);
      setReadyToLoadNewReports(true);
    }
  }, [reportsToShow, previousReportsToShowCount]);

  useEffect(() => {
    if (scrollPosition > fetchPosition && readyToLoadNewReports) {
      setTotalReportsToShow((pV) => pV + 10);
      setReadyToLoadNewReports(false);
    }
  }, [scrollPosition, fetchPosition, setTotalReportsToShow, readyToLoadNewReports]);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.innerHeight + document.documentElement.scrollTop);
      setFetchPosition(document.documentElement.offsetHeight * 0.7);
    };
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      {" "}
      {loading ? (
        <Box sx={{ textAlign: "center", pt: 3 }}>
          <CircularProgress size={60} />
        </Box>
      ) : (
        <>
          <Container maxWidth="lg">
            <Filter />
          </Container>
          <Container maxWidth="md">
            <PrintBar />
            {reportsToShow &&
              reportsToShow.map((report) => (
                <Report
                  report={report}
                  key={report.id}
                  admin={true}
                  setReportToDelete={setReportToDelete}
                />
              ))}
          </Container>
          <DeleteDialog setReportToDelete={setReportToDelete} reportToDelete={reportToDelete} />
        </>
      )}
    </>
  );
}
