import { useState, useCallback, useContext } from "react";
import * as Sentry from "@sentry/react";
import { FirestoreError } from "firebase/firestore";
import { ImageListType } from "react-images-uploading";
import { ReportInterface } from "../interfaces/interfaces";
import useAddDoc from "./useAddDoc";
import useUploadImages from "./useUploadImages";
import { AuthContext } from "../providers/AuthProvider";

interface SubmitFormProps {
  images: ImageListType;
  form: ReportInterface;
}

type SubmitFormType = (props: SubmitFormProps) => Promise<string | null>;

type UseSubmitFormType = {
  isLoading: boolean;
  error: string | null;
  submitForm: SubmitFormType;
};

const useSubmitForm = (): UseSubmitFormType => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { sendRequest: addDoc } = useAddDoc();
  const { currentUserData } = useContext(AuthContext);
  const { uploadImages, imagesError } = useUploadImages();

  const submitForm = useCallback<SubmitFormType>(
    async ({ form, images }) => {
      let newDocumentId: string | null = null;
      if (!currentUserData) {
        setError("No Logged In User");
        return null;
      }
      try {
        setIsLoading(true);
        const imageRef = await uploadImages({ images });
        newDocumentId = await addDoc({
          col: "reports",
          data: {
            ...form,
            uid: currentUserData.uid,
            imageRef: imageRef,
            studentIDs: form.students.map((student) => student.sid),
            author: currentUserData.displayName,
            authorEmail: currentUserData.email,
          },
        });
      } catch (err: unknown) {
        const firebaseError = err as FirestoreError;
        Sentry.captureException(firebaseError.message);
        setError(firebaseError.message);
      }
      if (imagesError) {
        setError(imagesError);
      }
      setIsLoading(false);

      return newDocumentId;
    },
    [uploadImages, addDoc, currentUserData, imagesError]
  );
  return { isLoading, error, submitForm };
};

export default useSubmitForm;
