import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    cardTitle: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    cardTitle?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    cardTitle: true;
    h3: false;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: "#26C6F5",
      contrastText: "#fff",
    },
    secondary: {
      main: "#B930E6",
    },
    error: {
      main: "#cd2929",
    },
  },
  typography: {
    fontFamily: [
      "Urbanist",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    cardTitle: {
      fontSize: 24,
    },
  },
});
