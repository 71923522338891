import { useState, useCallback } from "react";
import { FirestoreError } from "firebase/firestore";
import { getStorage, ref, uploadBytes, UploadResult } from "firebase/storage";
import { ImageListType } from "react-images-uploading";

interface uploadImagesProps {
  images: ImageListType;
}

type UploadImagesType = (props: uploadImagesProps) => Promise<string[] | null>;

type UseUploadImagesType = {
  imagesLoading: boolean;
  imagesError: string | null;
  uploadImages: UploadImagesType;
};

const useUploadImages = (): UseUploadImagesType => {
  const [imagesLoading, setImagesLoading] = useState(false);
  const [imagesError, setImagesError] = useState<string | null>(null);
  const storage = getStorage();

  const uploadImages = useCallback<UploadImagesType>(
    async ({ images }) => {
      if (images.length === 0) return [];
      setImagesLoading(true);
      setImagesError(null);
      let counter = 0;
      const imageRef: string[] = [];
      const promises: Array<Promise<UploadResult | null>> = [];
      images.forEach(async (i) => {
        if (i.file === undefined) return;
        const timestamp = Number(new Date());
        const multi = timestamp + counter;
        const name = multi.toString();
        const storageRef = ref(storage, name);
        try {
          const results = uploadBytes(storageRef, i.file);
          promises.push(results);
          counter++;
          imageRef.push(name);
          if (counter === images.length) {
            setImagesLoading(false);
          }
        } catch (error: unknown) {
          counter++;
          const firebaseError = error as FirestoreError;
          setImagesError(firebaseError.message);
          return null;
        }
      });
      await Promise.all(promises);
      return imageRef;
    },
    [storage]
  );
  return { imagesLoading, imagesError, uploadImages };
};

export default useUploadImages;
