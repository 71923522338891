import PerTeacherTable from "./PerTeacherTable";
import { Typography, Container } from "@mui/material";

export default function PerTeacherContainer() {
  return (
    <Container sx={{ backgroundColor: "#fff", pt: 2 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Reports Per Staff
      </Typography>
      <PerTeacherTable />
    </Container>
  );
}
